import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import VueSession from 'vue-session'
import Select2 from 'v-select2-component'
import VueMeta from 'vue-meta'

const dropIn = require('braintree-web-drop-in')

var options = {
  persist: true,
}

locale.use(lang)

Vue.use(VueMeta)
Vue.use(Element)
Vue.use(VueSession, options)
Vue.use(Select2)

Vue.prototype.$api = 'https://admin.smartlaundry.com/' //'http://smartlaundryadmin.arkinfosoft.in/'
// Vue.prototype.$website = 'https://www.smartlaundry.com' ''http://admin.smartlaundry.com/''
Vue.prototype.$secretKey = '@rk !nf0soft K*y'
Vue.prototype.$dropIn = dropIn

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
