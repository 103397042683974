<template>
  <div>
    <div class="loaderss" v-if="showloading"></div>
    <!-- Topbar -->
    <div class="et-topbar pb-3 pt-3 position-relative">
      <div class="container-fiuld">
        <div class="row justify-content-center m-0">
          <div class="social-media text-center text-md-left col-md-1" style="padding-left:15px">
            <a
              href="https://www.facebook.com/smartlaundryphoenix/"
              target="_blank"
              rel="noreferrer noopener"
              class="d-inline-block mr-2"
              ><i class="fab fa-facebook"></i
            ></a>
            <a
              href="https://twitter.com/smartlaundryphx"
              target="_blank"
              rel="noreferrer noopener"
              class="d-inline-block mr-2"
              ><i class="fab fa-twitter"></i
            ></a>
            <a
              href="https://www.instagram.com/smartlaundryllc"
              target="_blank"
              rel="noreferrer noopener"
              class="d-inline-block mr-2"
              ><i class="fab fa-instagram"></i
            ></a>
          </div>
          <div class="col-md-6 text-center text-white">
            <span class="pr-3 d-block d-lg-inline-block"
              ><i class="fas fa-clock"></i> 7:00 AM to 11:00 PM (Mon-Sun)</span
            >
            <span class="d-lg-inline-block d-md-block d-none"
              ><i class="fas fa-envelope"></i>support@smartlaundry.com</span
            >
          </div>

          <div
            class="
              col-md-2
              text-white
              d-md-flex
              text-md-right text-center
              langselect
              align-items-center
              justify-content-end
            "
          >
            <span v-if="isLogin">Welcome {{ displayName }}</span>
            <a
              v-bind:class="!isLogin ? '' : 'd-none'"
              href="#"
              class="login-popup-model text-white white-space-nowrap ml-2"
              id="LoginClick"
              data-toggle="modal"
              data-target="#loginModal"
              ><i class="fas fa-sign-in-alt"></i> Login/Signup</a
            >
            <a
              v-if="isLogin"
              href="#"
              class="text-white white-space-nowrap ml-2"
              @click="logOut()"
              ><i class="fas fa-sign-in-alt"></i>Logout</a
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Login Modal -->
    <div
      class="modal"
      id="loginModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="loginModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2
              class="modal-title w-100 text-center"
              id="loginModalLabel"
              style="font-family: 'Inter'"
            >
              Welcome Back
            </h2>

            <button
              type="button"
              class="close position-absolute rounded-pill bg-yellow text-white"
              data-dismiss="modal"
              id="close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-5">
            <p class="text-center">Sign In Continue</p>
            <form v-on:submit.prevent="login">
              <div class="form-group">
                <input
                  type="email"
                  required
                  class="form-control shadow-box bg-white border-white"
                  placeholder="Email"
                  v-model="username"
                  autocomplete="on"
                  v-bind:class="
                    this.reg.test(this.username) ? 'is-valid' : 'is-invalid'
                  "
                />
              </div>
              <div class="form-group">
                <div class="input-group mb-3">
                  <input
                    :type="passwordFieldType"
                    required
                    autocomplete="new-password"
                    class="form-control shadow-box bg-white border-white"
                    placeholder="Password"
                    v-model="password"
                  />
                  <div class="input-group-prepend">
                    <span @click="switchVisibility()" class="input-group-text"
                      ><i
                        :class="
                          passwordFieldType == 'password'
                            ? 'fas fa-eye'
                            : 'fas fa-eye-slash'
                        "
                      ></i
                    ></span>
                  </div>
                </div>
              </div>

              <input
                type="submit"
                class="btn text-white text-capitalize bit-bold bg-yellow"
                value="Log In"
              />

              <hr />
              <div class="text-center pt-25">
                <p>
                  Forgot password?
                  <a
                    href="#"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#resetModal"
                    class="text-yellow"
                    >Reset now</a
                  >
                </p>
                <p>
                  Don't have an account? <br /><a
                    href="#"
                    id="RegisterClick"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#signupModal"
                    class="btn text-white text-capitalize bit-bold bg-yellow"
                    @click="openRegister()"
                    style="margin-left: 2%"
                    >Sign up</a
                  >
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Password Reset Modal -->
    <div
      class="modal fade"
      id="resetModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="resetModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2
              class="modal-title w-100 text-center"
              id="resetModalLabel"
              style="font-family: 'Inter'"
            >
              Reset Password
            </h2>
            <button
              type="button"
              id="resetPasswordClose"
              class="close position-absolute rounded-pill bg-yellow text-white"
              @click="resetForgotModal()"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-5">
            <p class="text-center">We will send you otp to this email id</p>
            <form autocomplete="on" v-on:submit.prevent="forgotPassword">
              <div class="form-group">
                <input
                  v-model="forgotPasswordEmail"
                  type="email"
                  required
                  class="form-control shadow-box bg-white border-white"
                  placeholder="Your email"
                  v-bind:class="
                    this.reg.test(this.forgotPasswordEmail)
                      ? 'is-valid'
                      : 'is-invalid'
                  "
                />
                <label class="text-danger" v-if="forgotPasswordError"
                  >*Email invalid</label
                >
              </div>

              <input
                type="submit"
                class="btn text-white text-capitalize bit-bold bg-yellow"
                value="Submit"
              />
              <hr />
              <div class="text-center pt-25">
                <p>
                  Already have an account?<br />
                  <a
                    href="#"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#loginModal"
                    class="text-yellow"
                    >Sign in here</a
                  >
                </p>
                <p>
                  Don't have an account?<br />
                  <a
                    href="#"
                    id="clickRegister"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#signupModal"
                    class="text-yellow"
                    @click="openRegister()"
                    >Sign up here</a
                  >
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <a
      href="#"
      id="OtpClick"
      data-dismiss="modal"
      data-toggle="modal"
      data-target="#otpModal"
      class="text-yellow d-none"
      >OTP</a
    >

    <!-- OTP Modal For Reset Password -->
    <div
      class="modal fade"
      id="otpModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="otpModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2
              class="modal-title w-100 text-center"
              id="resetModalLabel"
              style="font-family: 'Inter'"
            >
              Reset Password
            </h2>
            <button
              type="button"
              class="close position-absolute rounded-pill bg-yellow text-white"
              id="ResetPasswordClose"
              @click="resetOtpForgotModal()"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-5">
            <form autocomplete="on" v-on:submit.prevent="otpForgotPassword">
              <div class="form-group">
                <label class="text-muted text-capitalize"
                  >Enter new password</label
                >
                <div class="input-group mb-3">
                  <input
                    :type="passwordFieldType"
                    autocomplete="new-password"
                    class="form-control"
                    placeholder="New Password"
                    aria-label="New Password"
                    aria-describedby="basic-addon1"
                    v-model="password"
                  />
                  <div class="input-group-prepend">
                    <span
                      @click="switchVisibility()"
                      class="input-group-text"
                      id="basic-addon1"
                      ><i
                        :class="
                          passwordFieldType == 'password'
                            ? 'fas fa-eye'
                            : 'fas fa-eye-slash'
                        "
                      ></i
                    ></span>
                  </div>
                </div>

                <label
                  v-if="has_minimum_lenth ? false : true"
                  class="text-muted"
                  >Password must be at least 6 characters</label
                >
              </div>

              <div class="form-group">
                <label class="text-muted text-capitalize"
                  >Confirm new password</label
                >
                <div class="input-group mb-3">
                  <input
                    :type="passwordFieldType1"
                    autocomplete="new-password"
                    class="form-control"
                    placeholder="Confirm New Password"
                    aria-label="Confirm New Password"
                    aria-describedby="basic-addon1"
                    v-model="resetConfirmPassword"
                  />
                  <div class="input-group-prepend">
                    <span
                      @click="switchVisibility(1)"
                      class="input-group-text"
                      id="basic-addon1"
                      ><i
                        :class="
                          passwordFieldType1 == 'password'
                            ? 'fas fa-eye'
                            : 'fas fa-eye-slash'
                        "
                      ></i
                    ></span>
                  </div>
                </div>

                <span
                  class="text-danger"
                  v-if="
                    password != resetConfirmPassword &&
                    resetConfirmPassword.length >= password.length &&
                    resetConfirmPassword != ''
                      ? true
                      : false
                  "
                  >*password does not match
                </span>
              </div>

              <div class="form-group">
                <p class="text-muted text-capitalize mb-0">Enter OTP</p>
                <OtpInput
                  ref="otpInput2"
                  input-classes="otp-input form-control text-center"
                  separator=""
                  :num-inputs="5"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-complete="handleOnComplete"
                  @on-change="handleOnChangeForgot"
                />
              </div>

              <input
                type="submit"
                :disabled="
                  resetConfirmPassword == password &&
                  has_minimum_lenth &&
                  otp.length == 5
                    ? false
                    : true
                "
                class="btn text-white text-capitalize bit-bold bg-yellow"
                value="Submit"
              />
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Signup Modal -->
    <div
      class="modal fade"
      id="signupModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="signupModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content register">
          <div class="modal-header">
            <h2
              class="modal-title w-100 text-center"
              id="signupModalLabel"
              style="font-family: 'Inter'"
            >
              Create an account
            </h2>
            <button
              type="button"
              id="registerClose"
              class="close position-absolute rounded-pill bg-yellow text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mr-0 et-add-restaurant">
            <div
              class="alert alert-danger alert-dismissible"
              v-if="isRegistrationError"
            >
              Something went wrong
            </div>

            <form autocomplete="on" v-on:submit.prevent="register" class="row">
              <div class="form-group col-lg-6 col-12">
                <input
                  type="text"
                  autocomplete="on"
                  v-bind:class="firstname != '' ? 'is-valid' : 'is-invalid'"
                  class="form-control"
                  placeholder="First Name"
                  required
                  v-model="firstname"
                />
              </div>

              <div class="form-group col-lg-6 col-12">
                <input
                  type="text"
                  autocomplete="on"
                  v-bind:class="lastname != '' ? 'is-valid' : 'is-invalid'"
                  class="form-control"
                  placeholder="Last Name"
                  required
                  v-model="lastname"
                />
              </div>

              <div class="form-group col-12">
                <input
                  type="email"
                  autocomplete="on"
                  v-bind:class="
                    this.reg.test(this.email) ? 'is-valid' : 'is-invalid'
                  "
                  class="form-control"
                  placeholder="Email"
                  required
                  v-model="email"
                />
                <span class="text-danger" v-if="isEmailExists"
                  >*Email already exists</span
                >
              </div>

              <div class="form-group col-12">
                <input
                  type="email"
                  autocomplete="on"
                  v-bind:class="
                    this.reg.test(this.confirmEmail) &&
                    this.email == this.confirmEmail
                      ? 'is-valid'
                      : 'is-invalid'
                  "
                  class="form-control"
                  placeholder="Confirm Email"
                  required
                  v-model="confirmEmail"
                />
                <span
                  class="text-danger"
                  v-if="
                    email != confirmEmail && email != '' && confirmEmail != ''
                  "
                  >*Email doesnt match</span
                >
              </div>

              <div class="form-group col-12">
                <div class="input-group mb-3">
                  <input
                    :type="passwordFieldType"
                    autocomplete="new-password"
                    class="form-control"
                    placeholder="Password"
                    aria-label="Password"
                    v-model="password"
                    v-bind:class="has_minimum_lenth ? 'is-valid' : 'is-invalid'"
                  />
                  <div class="input-group-prepend">
                    <span
                      @click="switchVisibility()"
                      class="input-group-text"
                      id="basic-addon1"
                      ><i
                        :class="
                          passwordFieldType == 'password'
                            ? 'fas fa-eye'
                            : 'fas fa-eye-slash'
                        "
                      ></i
                    ></span>
                  </div>
                </div>

                <label
                  v-if="has_minimum_lenth ? false : true"
                  class="text-muted"
                  >Password must be at least 6 characters</label
                >
              </div>

              <div class="form-group col-12">
                <div class="input-group mb-3">
                  <input
                    :type="passwordFieldType1"
                    autocomplete="new-password"
                    class="form-control"
                    placeholder="Confirm Password"
                    aria-label="Confirm Password"
                    aria-describedby="basic-addon1"
                    v-model="regConfirmPassword"
                    v-bind:class="
                      regConfirmPassword == password && regConfirmPassword != ''
                        ? 'is-valid'
                        : 'is-invalid'
                    "
                  />
                  <div class="input-group-prepend">
                    <span
                      @click="switchVisibility(1)"
                      class="input-group-text"
                      id="basic-addon1"
                      ><i
                        :class="
                          passwordFieldType1 == 'password'
                            ? 'fas fa-eye'
                            : 'fas fa-eye-slash'
                        "
                      ></i
                    ></span>
                  </div>
                </div>

                <span
                  class="text-danger"
                  v-if="
                    password != regConfirmPassword &&
                    regConfirmPassword.length >= password.length &&
                    regConfirmPassword != ''
                      ? true
                      : false
                  "
                  >*password does not match
                </span>
              </div>

              <div class="form-group col-12">
                <div
                  class="row"
                  style="padding-right: 15px; padding-left: 15px"
                >
                  <!-- <input
                    type="tel"
                    disabled="disabled"
                    value=""
                    class="form-control col-3 bg-white"
                    autocomplete="on"
                  /> -->
                  <input
                    type="tel"
                    autocomplete="on"
                    v-model="mobileNo"
                    v-bind:class="
                      mobileNo.length == 10 ? 'is-valid' : 'is-invalid'
                    "
                    required
                    class="form-control"
                    placeholder="Mobile"
                  />
                </div>
              </div>
              <input
                style="margin-left: 15px"
                type="submit"
                :disabled="
                  regConfirmPassword == password &&
                  this.reg.test(this.email) &&
                  this.email == this.confirmEmail &&
                  has_minimum_lenth &&
                  (mobileNo.length == 10 || mobileNo.length == 11)
                    ? false
                    : true
                "
                class="btn text-white text-capitalize bit-bold bg-yellow"
                value="Sign Up"
              />
              <hr />
              <div class="text-center pt-25 col-12">
                <p>
                  Already have an account?
                  <a
                    href="#"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#loginModal"
                    class="text-yellow"
                    >Sign in here</a
                  >
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TokenExpireLogin from "@/services/TokenExpireLogin";
import Login from "@/services/Login";
import Vue from "vue";
import store from "../store/index";
import OtpInput from "@bachdgvn/vue-otp-input";

export default {
  name: "Toolbar",

  data() {
    return {
      showloading: false,
      passwordFieldType: "password",
      passwordFieldType1: "password",
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      displayName: "",
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },

      //Login
      username: "",
      password: "",

      //validation
      has_minimum_lenth: false,

      //Forgot Password
      forgotPasswordEmail: "",
      forgotPasswordEmailTemp: "",
      forgotPasswordError: false,
      resetConfirmPassword: "",
      otp: "",

      //Register
      isPostcodeSearch: false,
      showAddressDiv: false,
      isValid: true,
      isError: false,
      firstname: "",
      lastname: "",
      email: "",
      confirmEmail: "",
      mobileNo: "",
      regConfirmPassword: "",
      isRegistrationError: false,
      isPasswordLength: null,
      isEmailExists: false,
      isLogin: false,
      registerOTP: "",

      d_none: "d-none",
    };
  },
  components: {
    OtpInput,
    //Select2
  },
  mounted() {
    if (
      localStorage.getItem("isLogin") == true ||
      localStorage.getItem("isLogin") == "true"
    ) {
      this.isLogin = true;

      const userData = this.$store.getters.getUser;
      this.displayName = userData["firstname"];
    } else {
      this.isLogin = false;
    }
  },
  methods: {
    switchVisibility(id) {
      if (id == 1) {
        this.passwordFieldType1 =
          this.passwordFieldType1 === "password" ? "text" : "password";
      } else {
        this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password";
      }
    },

    OpenOldRegister() {
      document.getElementById("otpRegisterModal").click();
      document.getElementById("RegisterClick").click();

      this.registerOTP = "";
      this.$refs.otpInput.clearInput();
    },
    onSuccess(googleUser) {
      console.log(googleUser.getBasicProfile());
    },
    onFailure(err) {
      console.log(err, "err");
    },

    openRegister() {
      if (this.$session.exists()) {
        var userObjectSession = this.$session.get("userObject");
        this.firstname = userObjectSession["firstname"];
        this.lastname = userObjectSession["lastname"];
        this.email = userObjectSession["email"];
        this.mobileNo = userObjectSession["contactNo"];
      }
    },
    async login() {
      this.showloading = true;
      const response = await Login.getLogin({
        username: this.username,
        password: this.password,
      });

      if (response) {
        if (response.data.isSuccess) {
          this.setStoreData(response.data.data1, response.data.data2);

          localStorage.setItem("isLogin", true);

          this.$root.$emit("changeLogin"); //like this
          this.$root.$emit("changeLoginNav");
          this.$root.$emit("changeLoginMob");

          this.isLogin = true;

          //Close Modal
          document.getElementById("close").click();

          //Clear input after login
          this.username = "";
          this.password = "";
          if (localStorage.getItem("refer-friend")) {
            localStorage.removeItem("refer-friend");
            this.$router.push("refer-friends");
          } else {
            localStorage.setItem("Refresh", true);
            if (!localStorage.getItem("TokenExpire")) {
              this.$router.go();
            }
          }

          this.$notify({
            title: "Login In",
            message: "Welcome to Smart laundry",
            type: "success",
          });
        } else {
          this.isError = true;
          this.$notify.error({
            title: "Incorrect details",
            message: "Retry email id or password",
          });

          //Clear input after login
          this.username = "";
          this.password = "";
        }
      } else {
        this.$notify.error({
          title: "Oops",
          message: "Something went wrong",
        });

        //Clear input after login
        this.username = "";
        this.password = "";
      }

      this.showloading = false;
    },

    async register() {
      this.showloading = true;

      const response = await Login.postCustomer({
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        mobileNo: this.mobileNo,
        plateform: "Web",
        loginDetails: {
          username: this.email,
          password: this.password,
        },
      });

      if (response) {
        //Registration succesfull
        if (response.data.isSuccess && response.data.statusCode != 3) {
          this.isRegistrationError = false;
          //this.responseData = response.data.data1;
          this.isLogin = true;

          this.setStoreData(response.data.data2, response.data.data1);

          //Clear all inputs
          (this.firstname = ""),
            (this.lastname = ""),
            (this.email = ""),
            (this.mobileNo = ""),
            (this.password = ""),
            (this.regConfirmPassword = ""),
            //Close Modal
            document.getElementById("registerClose").click();

          localStorage.setItem("isLogin", true);

          this.$root.$emit("changeLogin");
          this.$root.$emit("changeLoginNav");
          this.$root.$emit("changeLoginMob");

          //this.$router.go()
          this.$notify({
            title: "Registered",
            message: "Welcome to Smart laundry",
            type: "success",
          });

          localStorage.setItem("Refresh", true);
          this.$router.go();
        }
        //Registration failed
        else {
          if (response.data.statusCode == 3) {
            //Email already exists
            this.isEmailExists = true;
            this.$notify.error({
              title: "Email already exists",
              message: "Please provide another email",
            });

            this.isRegistrationError = false;
          } else {
            this.$notify.error({
              title: "Oops",
              message: "Something went wrong",
            });
          }
        }
      } else {
        if (localStorage.getItem("TokenExpire")) {
          const response = await TokenExpireLogin.tokenExpireLogin();
          console.log(response);
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      }

      this.showloading = false;
    },

    logOut() {
      localStorage.removeItem("token");
      localStorage.clear();
      localStorage.setItem("isLogin", false);
      this.isLogin = false;

      this.$root.$emit("changeLogin");
      this.$root.$emit("changeLoginNav");
      this.$root.$emit("changeLoginMob", false);
      this.$router.go();
    },

    async forgotPassword() {
      this.showloading = true;
      this.forgotPasswordEmailTemp = this.forgotPasswordEmail;
      const response = await Login.forgotPassword({
        username: this.forgotPasswordEmail,
      });

      if (response) {
        if (response.data.isSuccess) {
          document.getElementById("resetPasswordClose").click();
          document.getElementById("OtpClick").click();
        } else {
          this.forgotPasswordError = true;
        }
      } else {
        if (localStorage.getItem("TokenExpire")) {
          const response = await TokenExpireLogin.tokenExpireLogin();
          if (response.data.isSuccess) {
            this.forgotPassword();
          }
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      }

      this.showloading = false;
    },

    async otpForgotPassword() {
      this.showloading = true;
      const response = await Login.changePassword({
        username: this.forgotPasswordEmailTemp,
        newPassword: this.password,
        otp: this.otp,
      });

      if (response) {
        if (response.data.isSuccess) {
          this.$notify({
            title: "Successful",
            message: "New Password Set",
            type: "success",
          });

          this.password = "";
          this.resetConfirmPassword = "";
          this.otp = "";
          document.getElementById("ResetPasswordClose").click();

          this.forgotPasswordEmail = "";
          this.forgotPasswordEmailTemp = "";
        } else {
          this.$notify.error({
            title: "Incorrect OTP",
            message: "Enter correct OTP",
          });
        }
      } else {
        if (localStorage.getItem("TokenExpire")) {
          const response = await TokenExpireLogin.tokenExpireLogin();
          if (response.data.isSuccess) {
            this.otpForgotPassword();
          }
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      }

      this.showloading = false;
    },
    resetOtpForgotModal() {
      this.password = "";
      this.resetConfirmPassword = "";
      this.otp = "";
    },

    resetForgotModal() {
      this.forgotPasswordEmail = "";
    },

    handleOnComplete(value) {
      this.otp = value;
    },
    handleOnChangeForgot(value) {
      this.otp = value;
    },
    handleOnCompleteRegister(value) {
      this.registerOTP = value;
    },

    setStoreData(token, user) {
      this.displayName = user["firstname"];

      var emailEncrpt = this.$CryptoJS.AES.encrypt(
        user["email"],
        Vue.prototype.$secretKey
      ).toString();

      var passwordEncrypt = this.$CryptoJS.AES.encrypt(
        this.password.toString(),
        Vue.prototype.$secretKey
      ).toString();

      //Set data in store
      const userJson = {
        firstname: user["firstname"],
        lastname: user["lastname"],
        address1: user["address1"],
        address2: user["address2"],
        password: passwordEncrypt,
        email: emailEncrpt,
        id: this.$CryptoJS.AES.encrypt(
          user["id"].toString(),
          Vue.prototype.$secretKey
        ).toString(),
        mobileNo: user["mobileNo"].replace("+41-", ""),
        postCode: user["postCode"],
        referFriendCode: user["referFriendCode"],
        totalOrder: user["totalOrder"],
      };

      const encryptedToken = this.$CryptoJS.AES.encrypt(
        token,
        Vue.prototype.$secretKey
      ).toString();

      store.commit("setUser", userJson);
      store.commit("setToken", encryptedToken); //encryptedToken
    },
  },
  watch: {
    password() {
      this.has_minimum_lenth = this.password.length >= 6;
    },
  },
};
</script>

<style>
.modal .close {
  right: 23px;
  top: 21px;
}

.register .modal-body {
  position: relative;
  padding: 20px;
  height: 494px;
  overflow-y: scroll;
}

@media (max-width: 480px) {
  .modal .close {
    right: 93%;
    top: 21px;
  }
}
</style>

<style scoped>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
