import Vue from "vue";
import VueRouter from "vue-router";
import Orders from "../views/Orders.vue";
import Services from "../views/Services.vue";
import BookNow from "../views/BookNow.vue";
import Account from "../views/Account.vue";
import ChangePassword from "../views/ChangePassword.vue";
import CheckOut2 from "../views/Checkout2.vue";
import ConfirmPayment from "../views/ConfirmPayment.vue";
import PrivatePolicy from "../views/PrivatePolicy.vue";
import TermsConditions from "../views/TermsConditions.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/Pricing",
    name: "Pricing",
    component: Services,
    meta: {
      auth: false,
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivatePolicy",
    component: PrivatePolicy,
    meta: {
      auth: false,
    },
  },
  {
    path: "/terms-conditions",
    name: "TermsConditions",
    component: TermsConditions,
    meta: {
      auth: false,
    },
  },
  {
    path: "/confirm-payment",
    name: "ConfirmPayment",
    component: ConfirmPayment,
    meta: {
      auth: false,
    },
  },
  {
    path: "/Orders/:status?",
    name: "Orders",
    component: Orders,
    meta: {
      auth: true,
    },
  },
  {
    path: "/CheckoutPayment/:sessionId?",
    name: "CheckOut",
    component: CheckOut2,
    meta: {
      auth: false,
    },
  },
  {
    path: "/",
    alias: ["/book-now", "/book-now/:path?"],
    name: "BookNow",
    component: BookNow,
    meta: {
      auth: false,
    },
  },

  {
    path: "/ChangePassword",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      auth: true,
    },
  },
  {
    path: "/Account",
    name: "Account",
    component: Account,
    meta: {
      auth: true,
    },
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("@/views/BraintreeCheckout.vue"),
    meta: {
      auth: true,
      resource: 'Checkout'
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

// check if route is auth route
const isAuthRoute = (to) => to.matched.some((route) => route.meta.auth === true)

router.beforeEach((to, _, next) => {
  const loggedIn = localStorage.getItem("isLogin");
  const tokenId = JSON.parse(localStorage.getItem("TokenId"));
  const isLoggedIn = loggedIn === true || loggedIn === "true";

  if (!isLoggedIn && isAuthRoute(to)) return next({ name: 'BookNow' });
  // user cant direct access checkout page
  if (!tokenId && to.meta.resource === 'Checkout') return next({ name: 'Orders' })

  return next();
});

export default router;
