<template>
  <div>
    <section class="container-fluid et-add-restaurant">
      <div class="loaderss" v-if="showloading"></div>
      <div class="row justify-content-md-center">
        <!-- <customer-navigation :isLogin="isLogin"></customer-navigation> -->
        <!-- Content -->
        <div
          class="col-12 col-md-8 col-lg-6 overflow-hidden"
          style="margin-top: 4%"
        >
          <div
            class="alert alert-warning alert-dismissible fade show"
            role="alert"
            v-if="paymentStatus == 'false'"
          >
            <!-- v-if="paymentStatus == false" -->
            Your checkout session was expired, Place order again!
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <el-steps :active="activeStep" align-center>
            <el-step title="Address"></el-step>
            <el-step title="Services"></el-step>
            <el-step title="Collection"></el-step>
            <el-step title="Payment"></el-step>
          </el-steps>
          <form class="w-100 add-form mt-3" @submit.prevent>
            <!-- Address -->
            <div class="content-group" v-if="addressDiv">
              <div class="section-heading text-center">
                <h1 class="text-capitalize mb-4" style="font-family: 'Inter'">
                  Address <span class="text-yellow">Details</span>
                </h1>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group mb-0 margin-bottom">
                    <label class="text-muted text-capitalize"
                      >Enter Your Zip Code*</label
                    >
                    <input
                      id="postCode"
                      type="text"
                      v-model="postCode"
                      class="form-control bg-white shadow-box"
                      name="postCode"
                      placeholder="Enter Your Zip Code"
                      @blur="IsValidPostcode()"
                      required
                      v-bind:class="
                        !this.isValid || postCode == ''
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />

                    <label class="text-danger" v-if="!isValid">{{
                      isInValidText
                    }}</label>
                    <label v-else></label>
                  </div>
                </div>

                <div class="col-md-12" v-if="!showAddressDiv">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize"
                      >Please enter address *</label
                    >

                    <input
                      id="Address1"
                      type="text"
                      v-model="Address1"
                      class="form-control bg-white shadow-box"
                      placeholder="Please enter address"
                      required
                    />
                    <span
                      class="text-danger"
                      v-if="validateForm('address') && Address1 === ''"
                      >*Please Enter address</span
                    >
                  </div>
                </div>
                <div class="col-md-12" v-if="showAddressDiv">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize">
                      Please enter address details
                      <span
                        ><a
                          href="#"
                          class="text-yellow"
                          @click="
                            (showAddressDiv = !showAddressDiv), (Address1 = '')
                          "
                          ><b>*Do you want to select from list?</b></a
                        ></span
                      ></label
                    >
                    <textarea
                      rows="4"
                      cols="50"
                      class="form-control bg-white shadow-box"
                      v-model="Address1"
                      placeholder="Please enter address details"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize">
                      Please specify any extra address information</label
                    >
                    <textarea
                      rows="4"
                      cols="50"
                      class="form-control bg-white shadow-box"
                      v-model="Address2"
                      placeholder="Please enter extra address information"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div>
                <button
                  class="
                    btn
                    shadow
                    mt-3
                    bg-yellow
                    text-capitalize text-white
                    action-btn
                  "
                  style="float: right; width: 147px"
                  :disabled="validateForm('address')"
                  v-on:click="showhide('2')"
                >
                  Next
                </button>
              </div>
            </div>

            <!-- Login Page if user is not logged in -->
            <div class="content-group" v-if="isLoginDiv">
              <div class="section-heading text-center">
                <h2 class="text-capitalize mb-4" style="font-family: 'Inter">
                  Please <span class="text-yellow">Log in</span> to continue
                </h2>
              </div>
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <button
                    type="button"
                    class="
                      btn
                      shadow
                      mt-3
                      bg-yellow
                      text-capitalize text-white
                      action-btn
                      mr-2
                    "
                    v-on:click="showhide('1')"
                  >
                    Go Back
                  </button>
                  <a
                    data-toggle="modal"
                    data-target="#loginModal"
                    href="#"
                    class="
                      btn
                      shadow
                      mt-3
                      bg-yellow
                      text-capitalize text-white
                      action-btn
                    "
                    >Log In</a
                  >
                </div>
              </div>
            </div>

            <!-- Services -->
            <div class="content-group" v-if="servicesDiv">
              <div class="section-heading text-center">
                <h2 class="text-capitalize font-weight-bold mb-4">
                  Choose <span class="text-yellow">Services</span>
                </h2>
              </div>
              <div class="row">
                <div
                  class="search-result list-view et-cuisines col-sm-12 d-block"
                >
                  <div class="popular-list-items">
                    <!--Item -->
                    <div
                      class="popular-item shadow-box mt-4"
                      v-for="serviceItems in data1"
                      v-bind:key="serviceItems.serviceId"
                      @click="
                        addRemoveServices(
                          serviceItems.serviceId,
                          serviceItems.serviceName
                        )
                      "
                    >
                      <!--@click="((selectedServicesName.length==0) || (selectedServicesName[0]['service_name'] === serviceItems.serviceName))?
                        addRemoveServices(
                          serviceItems.serviceId,
                          serviceItems.serviceName
                        ):''
                      "-->
                      <small
                        class="
                          et-badge
                          pl-2
                          pr-2
                          bg-yellow
                          text-white
                          position-absolute
                        "
                        v-if="
                          selectedServicesList.indexOf(
                            serviceItems.serviceId
                          ) >= 0
                        "
                        >Added</small
                      >
                      <div class="d-sm-flex align-items-center">
                        <div class="text-center pr-sm-3 row ml-0 mr-0">
                          <div class="col-5 col-sm-6">
                            <img
                              :src="baseUrl + serviceItems.serviceIcon"
                              class="d-block m-auto h-20"
                              :alt="serviceItems.serviceName"
                              style=""
                            />
                          </div>
                          <div
                            class="
                              col-7 col-sm-6
                              pl-0
                              d-flex
                              align-items-center
                              d-lg-none d-sm-none
                            "
                          >
                            <h4 class="text-left link-hover">
                              {{ serviceItems.serviceName }}
                            </h4>
                          </div>
                        </div>
                        <div class="content w-100">
                          <!-- For sub services -->
                          <!-- <a
                            v-if="
                              !(
                                serviceItems.subServiceList != null &&
                                serviceItems.subServiceList.length
                              )
                            "
                            href="#"
                            class="position-absolute text-decoration-none wishlist rounded-pill text-white icon-bg bg-black"
                            style="border: initial"
                            ><i
                              v-bind:class="
                                selectedServicesList.indexOf(
                                  serviceItems.serviceId
                                ) >= 0
                                  ? 'fas fa-minus'
                                  : 'fas fa-plus'
                              "
                            ></i
                          ></a> -->
                          <!-- <a
                            v-if="
                              serviceItems.subServiceList != null &&
                              serviceItems.subServiceList.length &&
                              selectedServicesList.indexOf(
                                serviceItems.serviceId
                              ) >= 0 && selectedServicesName.length>0
                            "
                            href="#"
                            data-toggle="modal"
                            data-target="#subServicesModal"
                            class="position-absolute text-decoration-none wishlist rounded-pill text-white icon-bg bg-black"
                            style="border: initial"
                            ><i v-if="!selectedServicesName.length>0"
                              v-bind:class="
                                selectedServicesList.indexOf(
                                  serviceItems.serviceId
                                ) >= 0
                                  ? 'fas fa-minus'
                                  : 'fas fa-plus'
                              "
                            ></i
                          ></a> -->
                          <!--v-if="(selectedServicesName.length==0) || (selectedServicesName[0]['service_name'] === serviceItems.serviceName)"-->
                          <a
                            class="
                              position-absolute
                              text-decoration-none
                              wishlist
                              rounded-pill
                              text-white
                              icon-bg
                              bg-black
                            "
                            style="border: initial"
                            ><i
                              v-bind:class="
                                selectedServicesList.indexOf(
                                  serviceItems.serviceId
                                ) >= 0
                                  ? 'fas fa-minus'
                                  : 'fas fa-plus'
                              "
                            ></i
                          ></a>

                          <h4 class="d-none d-sm-block d-lg-block link-hover">
                            {{ serviceItems.serviceName }}
                          </h4>
                          <p class="mb-0">
                            {{
                              serviceItems.instruction
                                .replace('["', " ")
                                .replace('"]', " ")
                                .replace("[", " ")
                                .replace("]", " ")
                            }}
                          </p>
                          <p
                            class="
                              description
                              mt-3 mt-sm-4
                              mb-0 mb-sm-auto
                              mt-md-0
                              text-sm
                            "
                            style="padding-right: 6%"
                          >
                            {{ serviceItems.serviceDetail }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-4 pl-0 pr-0">
                      <div class="form-group mb-4">
                        <label class="text-muted text-capitalize"
                          >Any other request?</label
                        >
                        <textarea
                          v-model="specialInstructions"
                          class="form-control bg-white shadow-box"
                          rows="5"
                          placeholder="Add any special cleaning instructions or request"
                        ></textarea>
                      </div>
                    </div>

                    <span
                      class="text-danger"
                      v-if="selectedServicesList.length == 0"
                      >*Please select at least one service</span
                    >
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  class="
                    btn
                    shadow
                    mt-3
                    bg-yellow
                    text-capitalize text-white
                    action-btn
                  "
                  style="float: left; width: 147px"
                  v-on:click="showhide('1')"
                >
                  Previous
                </button>
                <button
                  type="button"
                  class="
                    btn
                    shadow
                    mt-3
                    bg-yellow
                    text-capitalize text-white
                    action-btn
                  "
                  style="float: right; width: 147px"
                  :disabled="validateForm('services')"
                  v-on:click="showhide('3')"
                >
                  Next
                </button>
              </div>
            </div>

            <!-- Collection -->
            <div class="content-group" v-if="collectionDiv">
              <div class="section-heading text-center">
                <h2 class="text-capitalize font-weight-bold mb-4">
                  Pickup <span class="text-yellow">Time</span>
                </h2>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-4 selectwide">
                    <label class="text-muted text-capitalize"
                      >Pickup Date *</label
                    >
                    <br />
                    <Select2
                      id="collectionDay"
                      v-model="collectionDay"
                      :options="collectionDayOption"
                      placeholder="Select Pickup Date"
                      @change="getCollectionTime()"
                    />
                    <!-- @change="getCollectionTime()" -->
                    <!-- :options="collectionDayOption"-->
                    <span class="text-danger" v-if="collectionDay == ''"
                      >*Please select Pickup day</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4 selectwide">
                    <label class="text-muted text-capitalize"
                      >Pickup Time *</label
                    >
                    <br />

                    <Select2
                      id="collectionTime"
                      v-model="collectionTime"
                      :options="collectionTimeOption"
                      placeholder="Select Pickup Time"
                      @change="getDeliveryDate()"
                    />
                    <!--:disabled="collectionTimeDisable" @change="getDeliveryDate()"-->
                    <span
                      class="text-danger"
                      v-if="collectionDay != '' && collectionTime == ''"
                      >*Please select pickup time</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4 selectwide">
                    <label class="text-muted text-capitalize"
                      >Pickup Option *</label
                    >
                    <br />
                    <Select2
                      id="collectionOption"
                      v-model="collectionOption"
                      :options="pickupOptions"
                      placeholder="Select pickup option"
                    />
                    <span class="text-danger" v-if="collectionOption == ''"
                      >*Please select pickup option</span
                    >
                  </div>
                </div>
              </div>
              <br />
            </div>

            <!-- Delivery -->
            <div class="content-group" v-if="deliveryDiv">
              <div class="section-heading text-center">
                <h2 class="text-capitalize font-weight-bold mb-4">
                  Delivery <span class="text-yellow">Time</span>
                </h2>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-4 selectwide">
                    <label class="text-muted text-capitalize"
                      >Delivery Date *</label
                    >
                    <br />
                    <Select2
                      id="deliveryDay"
                      v-model="deliveryDay"
                      :options="deliveryDayOptions"
                      placeholder="Select Delivery Day"
                      @change="getDeliveryTime()"
                    />
                    <!--:disabled="deliveryDayDisable" @change="getDeliveryTime()"-->
                    <span
                      class="text-danger"
                      v-if="deliveryDay == '' && collectionTime != ''"
                      >*Please select delivery day</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4 selectwide">
                    <label class="text-muted text-capitalize"
                      >Delivery Time *</label
                    >
                    <br />
                    <Select2
                      id="deliveryTime"
                      v-model="deliveryTime"
                      :options="deliveryTimeOption"
                      placeholder="Select Delivery Time"
                    />
                    <span
                      class="text-danger"
                      v-if="deliveryTime == '' && deliveryDay != ''"
                      >*Please select delivery time</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-4 selectwide">
                    <label class="text-muted text-capitalize"
                      >Delivery Option *</label
                    >
                    <br />
                    <!--deliveryOption -->
                    <Select2
                      id="deliveryOption"
                      v-model="deliveryOption"
                      :options="dropOffOptions"
                      placeholder="Select delivery option"
                    />
                    <span class="text-danger" v-if="deliveryOption == ''"
                      >*Please select delivery option</span
                    >
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize"
                      >Delivery Instruction?</label
                    >
                    <textarea
                      v-model="deliveryNotes"
                      class="form-control bg-white shadow-box"
                      rows="5"
                      placeholder="Enter delivery instruction"
                    ></textarea>
                  </div>
                  <span class="text-danger" v-if="validateForm('collection')"
                    >*Please fill all details</span
                  >
                </div>
              </div>

              <div>
                <button
                  type="button"
                  class="
                    btn
                    shadow
                    mt-3
                    bg-yellow
                    text-capitalize text-white
                    action-btn
                  "
                  style="float: left; width: 147px"
                  v-on:click="showhide('2')"
                >
                  Previous
                </button>
                <button
                  type="button"
                  class="
                    btn
                    shadow
                    mt-3
                    bg-yellow
                    text-capitalize text-white
                    action-btn
                  "
                  style="float: right; width: 147px"
                  :disabled="validateForm('collection')"
                  v-on:click="showhide('4')"
                >
                  Next
                </button>
              </div>
            </div>

            <div class="content-group" v-if="personalDiv">
              <div class="section-heading text-center mb-4">
                <h2 class="text-capitalize font-weight-bold">
                  Personal <span class="text-yellow">Information</span>
                </h2>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize"
                      >First Name *</label
                    >
                    <input
                      type="text"
                      autocomplete="on"
                      required
                      v-model="Firstname"
                      v-bind:class="Firstname != '' ? 'is-valid' : 'is-invalid'"
                      class="form-control bg-white shadow-box"
                      placeholder="Please enter first name"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize"
                      >Last Name *</label
                    >
                    <input
                      type="text"
                      autocomplete="on"
                      required
                      v-model="Lastname"
                      v-bind:class="Lastname != '' ? 'is-valid' : 'is-invalid'"
                      class="form-control bg-white shadow-box"
                      placeholder="Please enter last name"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize">Email Id *</label>
                    <input
                      type="email"
                      autocomplete="on"
                      required
                      v-model="Email"
                      v-bind:class="
                        Email != '' && this.reg.test(this.Email)
                          ? 'is-valid'
                          : 'is-invalid'
                      "
                      class="form-control bg-white shadow-box"
                      placeholder="Please enter email"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize">Zip Code *</label>
                    <input
                      type="text"
                      autocomplete="on"
                      disabled="disabled"
                      class="form-control bg-white shadow-box"
                      v-model="postCode"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize"
                      >Address Line1 *</label
                    >
                    <input
                      type="text"
                      autocomplete="on"
                      disabled="disabled"
                      class="form-control bg-white shadow-box"
                      v-model="Address1"
                    />
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize">
                      Extra Address Details</label
                    >
                    <input
                      type="text"
                      autocomplete="on"
                      v-model="Address2"
                      class="form-control bg-white shadow-box"
                      placeholder="Please enter extra address details"
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-sm-12">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize"
                      >Mobile No *</label
                    >
                    <div
                      class="row"
                      style="padding-right: 15px; padding-left: 15px"
                    >
                      <input
                        type="tel"
                        autocomplete="on"
                        required
                        v-model="MobileNo"
                        v-bind:class="
                          this.MobileNo.length == 10 ? 'is-valid' : 'is-invalid'
                        "
                        class="form-control bg-white shadow-box"
                        placeholder="Please enter mobile"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group mb-4">
                    <label class="text-muted text-capitalize"
                      >Voucher Code
                      <span class="text-yellow" v-if="!isLogin"
                        >* Please
                        <a
                          href="#"
                          class="text-yellow text-uppercase"
                          data-toggle="modal"
                          data-target="#loginModal"
                          >log in</a
                        >
                        to apply code</span
                      >
                      <span v-html="voucherText"> </span>
                    </label>
                    <div
                      class="row"
                      style="padding-right: 15px; padding-left: 15px"
                    >
                      <input
                        type="text"
                        autocomplete="on"
                        v-model="ReferFriendCode"
                        :disabled="!isLogin"
                        class="form-control bg-white shadow-box col-9 col-lg-5"
                        placeholder="Please enter voucher code"
                      />
                      <div
                        class="form-group mb-4 col-4 pl-0 ml-0"
                        style="height: auto; width: auto"
                      >
                        <button
                          type="button"
                          class="
                            btn
                            shadow
                            bg-yellow
                            text-capitalize text-white
                            action-btn
                          "
                          :disabled="ReferFriendCode.length > 0 ? false : true"
                          v-on:click="verifyVoucher()"
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <!-- Description -->
                  <div class="content-widget">
                    <div
                      class="content-box shadow-box p-3"
                      style="background-color: #ebedef"
                    >
                      <p class="description mb-0 text-muted text-justify">
                        <b>Note: </b>We will authorise your card with a
                        pre-payment of &#36;30. The final value is calculated
                        after we count / weigh your order. You can calculate the
                        approximate price using our price list. Our minimum
                        order is &#36;30.
                      </p>
                    </div>
                  </div>
                  <span
                    class="text-danger"
                    v-if="
                      this.Firstname == '' ||
                      this.Lastname == '' ||
                      this.Email == ''
                    "
                    >*Please fill all details<br
                  /></span>
                  <span
                    class="text-danger"
                    v-if="
                      !(
                        this.MobileNo.length == 11 || this.MobileNo.length == 10
                      )
                    "
                    >*Please enter correct mobile number<br
                  /></span>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  class="
                    btn
                    shadow
                    mt-3
                    bg-yellow
                    text-capitalize text-white
                    action-btn
                  "
                  style="width: 147px"
                  @click="showhide('3')"
                >
                  Previous
                </button>
                <button
                  type="submit"
                  class="
                    btn
                    shadow
                    mt-3
                    bg-yellow
                    text-capitalize text-white
                    action-btn
                  "
                  style="float: right; width: 147px"
                  @click="insertOrder()"
                  :disabled="validateForm('personal')"
                >
                  Check Out
                </button>
                <p class="pt-4 text-muted text-justify">
                  By continuing you agree to our
                  <a href="#" target="_blank" class="text-yellow"
                    >Terms & Conditions
                  </a>
                  and
                  <a href="#" target="_blank" class="text-yellow"
                    >Privacy Policy</a
                  >. We will authorize your card with a pre payment of &#36;30
                </p>
              </div>
            </div>

            <br />
          </form>
        </div>
        <br />
        <!-- Summary -->
        <div class="col-sm-10 col-lg-3 offset-lg-0" style="margin-top: 2%">
          <div
            class="shadow-box p-4 bg-white"
            style="position: sticky; top: 4rem"
          >
            <!-- Sidebar Widget -->
            <div class="side-widget mt-4">
              <h5
                class="
                  widget-heading
                  position position-relative
                  mt-3
                  text-capitalize text-center
                  font-weight-bold
                "
              >
                Summary
              </h5>
              <div class="widget-content">
                <div class="order pt-3" v-if="!validateForm('address')">
                  <a
                    href="#"
                    class="font-weight-bold text-yellow text-uppercase"
                    @click="!validateForm('address') ? showhide('1') : ''"
                    >Address</a
                  >
                  <i
                    class="remove-order text-yellow fas fa-edit float-right"
                    @click="!validateForm('address') ? showhide('1') : ''"
                  ></i>

                  <p class="description">{{ Address1 }} {{ postCode }}</p>
                </div>

                <div class="order pt-3" v-if="!validateForm('services')">
                  <a
                    href="#"
                    class="font-weight-bold text-yellow text-uppercase"
                    @click="!validateForm('services') ? showhide('2') : ''"
                    >Services</a
                  >
                  <i
                    class="remove-order text-yellow fas fa-edit float-right"
                    @click="!validateForm('services') ? showhide('2') : ''"
                  ></i>

                  <p class="description">
                    <span
                      class="
                        tag
                        rounded
                        text-muted text-capitalize
                        mb-2
                        d-inline-block
                        mr-2
                      "
                      v-for="item in selectedServicesName"
                      v-bind:key="item['service_id']"
                    >
                      {{ item["service_name"] }}
                    </span>
                  </p>
                </div>

                <div
                  class="order pt-3"
                  v-if="
                    !validateForm('services') &&
                    (this.isLogin == true || this.isLogin == 'true')
                  "
                >
                  <a
                    href="#"
                    class="font-weight-bold text-yellow text-uppercase"
                    @click="!validateForm('collection') ? showhide('3') : ''"
                    >Pickup</a
                  >
                  <i
                    class="remove-order text-yellow fas fa-edit float-right"
                    @click="!validateForm('collection') ? showhide('3') : ''"
                  ></i>

                  <p class="description">
                    {{ collectionDay }} {{ collectionTime }}
                  </p>
                </div>
                <div
                  class="order pt-3"
                  v-if="
                    !validateForm('services') &&
                    (this.isLogin == true || this.isLogin == 'true')
                  "
                >
                  <a
                    href="#"
                    class="font-weight-bold text-yellow text-uppercase"
                    @click="!validateForm('collection') ? showhide('3') : ''"
                    >Delivery</a
                  >
                  <i
                    class="remove-order text-yellow fas fa-edit float-right"
                    @click="!validateForm('collection') ? showhide('3') : ''"
                  ></i>

                  <p class="description">
                    {{ deliveryDay }} {{ deliveryTime }}
                  </p>
                </div>

                <div
                  class="order pt-3"
                  v-if="
                    !validateForm('services') &&
                    (this.isLogin == true || this.isLogin == 'true')
                  "
                >
                  <a
                    href="#"
                    class="font-weight-bold text-yellow text-uppercase"
                    @click="!validateForm('collection') ? showhide('4') : ''"
                    >Payment</a
                  >
                  <i
                    class="remove-order text-yellow fas fa-edit float-right"
                    @click="!validateForm('collection') ? showhide('4') : ''"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </section>
    <!-- Sub services Modal -->
    <div
      class="modal fade"
      id="subServicesModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="serviceModalLabel"
      aria-hidden="true"
      v-if="selectedServices.length"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2
              class="modal-title w-100 text-center"
              id="serviceModalLabel"
              style="font-family: 'Inter"
            >
              Please select your preference for wash
            </h2>

            <button
              type="button"
              class="close position-absolute rounded-pill bg-yellow text-white"
              @click="restoreOldService()"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            class="
              modal-body
              py-5
              px-4
              p-sm-5
              container-fluid
              et-add-restaurant
            "
          >
            <div class="row justify-content-md-center">
              <div class="col-md-12 overflow-hidden">
                <div class="content-group">
                  <div class="row">
                    <div
                      class="
                        search-result
                        list-view
                        et-cuisines
                        col-sm-12
                        d-block
                      "
                    >
                      <div
                        class="
                          popular-list-items
                          grid
                          gap-2
                          grid-cols-2
                          d-sm-block
                        "
                      >
                        <!--Item -->
                        <div
                          class="popular-item shadow-box mt-4"
                          v-for="subserviceItem in selectedServices[0]
                            .subServiceList"
                          v-bind:key="subserviceItem.subServiceId"
                          v-on:click="
                            addRemoveSubServices(subserviceItem.subServiceId)
                          "
                        >
                          <small
                            class="
                              et-badge
                              pl-2
                              pr-2
                              bg-yellow
                              text-white
                              position-absolute
                            "
                            v-if="
                              tempSelectedSubServiceList.indexOf(
                                subserviceItem.subServiceId
                              ) >= 0
                            "
                            >Added</small
                          >
                          <div class="d-sm-flex align-items-center">
                            <div class="text-center pr-sm-3 row ml-0 mr-0">
                              <div class="col-12 d-none d-lg-block">
                                <img
                                  :src="baseUrl + subserviceItem.serviceIcon"
                                  class="d-block m-auto"
                                  :alt="subserviceItem.serviceName"
                                  style="width: 50%"
                                />
                              </div>
                              <div class="col-12 d-lg-none">
                                <img
                                  :src="baseUrl + subserviceItem.serviceIcon"
                                  class="d-block m-auto h-20"
                                  :alt="subserviceItem.serviceName"
                                  style="width: 4rem; object-fit: contain"
                                />
                              </div>
                              <div
                                class="
                                  col-sm-12 col-lg-6
                                  pl-0
                                  d-flex
                                  align-items-center
                                "
                              >
                                <h4
                                  class="d-lg-none text-justify link-hover mb-0"
                                >
                                  {{ subserviceItem.serviceName }}
                                </h4>
                              </div>
                            </div>
                            <div class="content w-100">
                              <!-- <a v-if="!selectedSubServicesList.indexOf(subserviceItem.subServiceId) >= 0" href="#" class="position-absolute text-decoration-none wishlist rounded-pill text-white icon-bg bg-black"><i v-bind:class=" (selectedSubServicesList.indexOf(subserviceItem.subServiceId) >= 0) ? '': 'fas fa-plus' "></i></a> -->
                              <a
                                v-if="
                                  tempSelectedSubServiceList.indexOf(
                                    subserviceItem.subServiceId
                                  ) <= -1
                                "
                                href="#"
                                class="
                                  position-absolute
                                  text-decoration-none
                                  wishlist
                                  rounded-pill
                                  text-white
                                  icon-bg
                                  bg-black
                                "
                                ><i class="fas fa-plus"></i
                              ></a>

                              <h4 class="d-none d-lg-block link-hover">
                                {{ subserviceItem.serviceName }}
                              </h4>
                              <p
                                class="
                                  description
                                  mt-3
                                  mb-sm-4
                                  mt-md-0
                                  text-sm
                                  mb-0
                                "
                                style=""
                              >
                                {{ subserviceItem.description }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                </div>

                <button
                  data-dismiss="modal"
                  @click="SaveSubService()"
                  class="
                    btn
                    shadow
                    mt-3
                    bg-yellow
                    text-capitalize text-white
                    action-btn
                  "
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Instructions for 1st time -->
    <div
      class="modal fade"
      id="instructionModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="instructionModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2
              class="modal-title w-100 text-center"
              id="instructionModalLabel"
              style="font-family: 'Inter'"
            >
              About Our Service
            </h2>

            <button
              type="button"
              class="close position-absolute rounded-pill bg-yellow text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="">
              <div class="et-sidebar">
                <!-- Sidebar Widget -->
                <div class="side-widget">
                  <div class="widget-content">
                    <h5
                      class="
                        widget-heading
                        position position-relative
                        mt-3
                        mb-4
                        text-capitalize text-center
                        font-weight-bold
                      "
                    >
                      Item List
                    </h5>
                    <div class="meta mb-3 text-left d-flex pr-1 pl-1">
                      <i
                        class="
                          rounded-pill
                          d-inline-block
                          mr-2
                          icon-bg
                          bg-white
                        "
                      >
                        <i class="fas fa-check f-icon"></i>
                      </i>
                      <p class="align-bottom description d-inline-block m-0">
                        No need to list your item. We will weigh and count your
                        items for you once we collect your laundry.
                      </p>
                    </div>

                    <div class="meta mb-3 text-left d-flex pr-1 pl-1">
                      <i
                        class="
                          rounded-pill
                          d-inline-block
                          mr-2
                          icon-bg
                          bg-white
                        "
                      >
                        <i class="fas fa-check f-icon"></i>
                      </i>
                      <p class="align-bottom description d-inline-block m-0">
                        We will charge your credit card after we receive your
                        laundry before delivery.
                      </p>
                    </div>

                    <h5
                      class="
                        widget-heading
                        position position-relative
                        mt-3
                        mb-4
                        text-capitalize text-center
                        font-weight-bold
                      "
                    >
                      Prices
                    </h5>
                    <div class="meta mb-3 text-left d-flex pr-1 pl-1">
                      <i
                        class="
                          rounded-pill
                          d-inline-block
                          mr-2
                          icon-bg
                          bg-white
                        "
                      >
                        <i class="fas fa-check f-icon"></i>
                      </i>
                      <p class="align-bottom description d-inline-block m-0">
                        You can check our price list to estimate the cost.
                      </p>
                    </div>

                    <div class="meta mb-3 text-left d-flex pr-1 pl-1">
                      <i
                        class="
                          rounded-pill
                          d-inline-block
                          mr-2
                          icon-bg
                          bg-white
                        "
                      >
                        <i class="fas fa-check f-icon"></i>
                      </i>
                      <p class="align-bottom description d-inline-block m-0">
                        $30 minimum
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a
      href="#"
      id="checkoutClick"
      data-dismiss="modal"
      data-toggle="modal"
      data-target="#checkoutModal"
      class="text-yellow"
      style="display: none"
    ></a>
    <a
      href="#"
      id="instructionsClick"
      data-dismiss="modal"
      data-toggle="modal"
      data-target="#instructionModal"
      class="text-yellow"
      style="display: none"
    ></a>
  </div>
</template>

<script>
import Vue from "vue";
import TokenExpireLogin from "@/services/TokenExpireLogin";
// import CustomerNavigation from "@/core/CustomerNavigation.vue";
import OrderNow from "@/services/OrderNow";
import Services from "@/services/GetServices";
import VerifyReferFriendCode from "@/services/VerifyReferFriendCode";
import moment from "moment";
import { decryptAES } from "@/utils/crypto";
import Select2 from "v-select2-component";

export default {
  name: "BookNow",
  data() {
    return {
      showloading: false,
      totalOrder: 0,
      isOfferCode: false,
      isReferCode: false,

      postCode: "",
      addressList: [],
      allAddressList: [],
      isValid: false,
      isValidText: "",
      isInValidText: "",
      showAddressDiv: false,
      validPostCodes: [],
      checkFlag: false,

      addressDiv: true,
      isLoginDiv: false,

      data1: [], //Services
      selectedServices: [],
      servicesDiv: false,
      selectedServicesList: [],
      selectedServicesName: [],
      selectedSubServiceName: [],
      selectedSubServicesList: [],
      selectedServiceSubServiceList: [],
      tempSelectedServiceSubServiceList: [],
      tempSelectedSubServiceList: [],
      specialInstructions: "",
      dropOffTimeDuration: 0,
      tempSubServiceId: 0,

      data2: [], //Collection/Delivery Date Time
      collectionDiv: false,
      deliveryDiv: false,
      collectionDay: "",
      collectionDayOption: [],
      collectionDayOptionValue: "",
      collectionTime: "",
      collectionTimeOption: [],
      collectionOption: "",
      pickupOptions: [
        "Driver pickup from you",
        "Driver pickup from reception/porter",
      ],
      collectionTimeDisable: true,
      deliveryDay: "",
      deliveryTime: "",
      deliveryTimeOption: [],
      deliveryDayOptions: [],
      deliveryOption: "",
      dropOffOptions: [
        "Driver delivers to you",
        "Driver delivers to reception/porter",
      ],
      deliveryDayDisable: true,
      deliveryTimeDisable: true,
      minimumDeliveryDate: "",
      deliveryNotes: "",

      personalDiv: false,
      Firstname: "",
      Lastname: "",
      Email: "",
      MobileNo: "",
      Address1: "",
      Address2: "",
      ReferFriendCode: "",
      voucherValid: true,
      voucherText: "",
      activeStep: 1,
      userId: 0,
      orderName: "",

      paymentDiv: false,
      cardName: "",
      complete: false,
      // termsheck:false,
      paymentStatus: true,
      inService: "",
      serviceId: "",
      isLogin: false,
      baseUrl: this.$api,
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  components: {
    // CustomerNavigation,
    Select2,
    //Card
  },
  mounted() {
    this.getServicesAndTimeSlot(); // get services data
    this.paymentStatus = localStorage.getItem("paymentStatus");
    localStorage.removeItem("paymentStatus");

    this.postCode = this.$store.getters.getPostcode;
    if (this.postCode != null) {
      this.IsValidPostcode();
    }

    this.Address1 = this.$store.getters.getAddress;

    // Fill data again after login refresh
    if (
      localStorage.getItem("Postcode") != null &&
      localStorage.getItem("Postcode") != "" &&
      localStorage.getItem("Postcode") != "null"
    ) {
      this.postCode = localStorage.getItem("Postcode");
    }

    this.isLogin = localStorage.getItem("isLogin");

    if (this.isLogin == true || this.isLogin == "true") {
      const userData = this.$store.getters.getUser;
      this.isLoginDiv = false;
      this.Firstname = userData["firstname"];
      this.Lastname = userData["lastname"];
      this.Address2 = userData["address2"];
      this.Email = decryptAES(userData["email"]);
      this.MobileNo = userData["mobileNo"];
      this.userId = Number(decryptAES(userData["id"]));
      this.totalOrder = Number(userData["totalOrder"]);
    }

    this.$root.$on("changeLogin", () => {
      this.isLogin = localStorage.getItem("isLogin");
      if (this.isLoginDiv) {
        this.showhide(2);
      }

      const userData = this.$store.getters.getUser;
      if (this.Firstname == "") {
        this.Firstname = userData["firstname"];
        this.Lastname = userData["lastname"];
      }

      if (this.Address2 == "") {
        this.Address2 = userData["address2"];
      }
      if (this.Email == "") {
        this.Email = decryptAES(userData["email"]);
      }
      if (this.MobileNo == "") {
        this.MobileNo = userData["mobileNo"];
      }

      this.userId = Number(decryptAES(userData["email"]));
      this.totalOrder = Number(userData["totalOrder"]);
    });

    if (this.postCode == null) {
      this.postCode = "";
    }
  },
  watch: {
    ReferFriendCode() {
      this.voucherText = "";
      this.isReferCode = false;
      this.offerCode = false;

      if (this.ReferFriendCode.length) {
        this.voucherText =
          "<span class='text-muted'> <b> *Voucher not applied </b> </span>";
      }
    },
    postCode() {
      //Fill Data again after login refresh
      if (
        localStorage.getItem("Refresh") == true ||
        localStorage.getItem("Refresh") == "true"
      ) {
        localStorage.setItem("Refresh", false);

        if (
          localStorage.getItem("Postcode") != null &&
          localStorage.getItem("Postcode") != "" &&
          localStorage.getItem("Postcode") != "null"
        ) {
          this.postCode = localStorage.getItem("Postcode");
          if (this.postCode != null && this.postCode != "" && this.postCode) {
            this.IsValidPostcode();
            this.showhide("2");
          }
        }

        if (
          localStorage.getItem("Address2") != null &&
          localStorage.getItem("Address2") != "" &&
          localStorage.getItem("Address2") != "null"
        ) {
          this.Address2 = localStorage.getItem("Address2");
        }

        if (
          localStorage.getItem("Address1") != null &&
          localStorage.getItem("Address1") != "" &&
          localStorage.getItem("Address1") != "null"
        ) {
          this.Address1 = localStorage.getItem("Address1");
        }

        localStorage.removeItem("Postcode");
        localStorage.removeItem("Address1");
        localStorage.removeItem("Address2");
        localStorage.removeItem("Refresh");
      } else {
        this.isValidText = "";
        // this.isValid = true;
        if (
          this.postCode != null &&
          this.postCode.length > 0 &&
          this.isLogin != true &&
          this.isLogin != "true"
        ) {
          localStorage.setItem("Postcode", this.postCode);
        }
      }
    },
    Address1() {
      if (
        this.Address1 != null &&
        this.Address1.length > 0 &&
        this.isLogin != true &&
        this.isLogin != "true"
      ) {
        localStorage.setItem("Address1", this.Address1);
      }
    },
    Address2() {
      if (
        this.Address2 != null &&
        this.Address2.length > 0 &&
        this.isLogin != true &&
        this.isLogin != "true"
      ) {
        localStorage.setItem("Address2", this.Address2);
      }
    },
    // selectedServicesName() {
    //   this.getCollectionDate();
    // },
    // selectedServicesList() {
    //   this.getCollectionDate();
    // },
    collectionDay() {
      this.getCollectionTime();
    },
    collectionTime() {
      this.getDeliveryDate();
    },
    deliveryDay(){
      this.getDeliveryTime();
    },
  },
  methods: {
    async getServicesAndTimeSlot() {
      const response = await Services.getServicesAndTimeSlot();
      if (response.data.isSuccess) {
        // Get services list
        this.data1 = response.data.data1;
        //Collection Delivery Time Date
        this.data2 = response.data.data2;
        // this.getCollectionDate();

        // for (let x = 0; x < this.data2.length; x++) {
        //   this.collectionDayOption.push(this.data2[x]["date"]);
        // }
        // this.collectionDay=this.collectionDayOption[0]
        this.collectionOption = this.pickupOptions[0];
      } else {
        this.$notify.error({
          title: "Oops",
          message: "Something went wrong",
        });
      }
    },
    async IsValidPostcode() {
      this.showloading = true;

      if (this.postCode.length > 0) {
        const response = await OrderNow.IsActivePostcode(
          this.postCode.toUpperCase()
        );

        if (response.data.data1 == true) {
          this.isInValidText = "";
          this.isValid = true;
          this.$store.commit("setPostcode", this.postCode);
          // this.isValidText = "We serve in your area";
        } else if (response.data.data1 == false) {
          this.isValid = false;
          // this.isValidText = "";
          this.isInValidText = "We dont serve in your area";
        } else {
          if (localStorage.getItem("TokenExpire")) {
            const response = await TokenExpireLogin.tokenExpireLogin();
            if (response.data.isSuccess) {
              this.IsValidPostcode();
            }
          } else {
            this.$notify.error({
              title: "Oops",
              message: "Something went wrong",
            });
          }
        }
      }

      this.showloading = false;
    },
    getCollectionDate() {
      // Get maximum drop off duration from services
      for (let i = 0; i < this.selectedServicesList.length; i++) {
        var tempObj = this.data1.filter(
          (d) => d.serviceId === this.selectedServicesList[i]
        );
        this.dropOffTimeDuration = 0;
        if (this.dropOffTimeDuration < tempObj[0]["dropOffTimeDuration"]) {
          this.dropOffTimeDuration = tempObj[0]["dropOffTimeDuration"].trim();
        }
      }
      let days = this.dropOffTimeDuration / 24;
      this.collectionDayOption = []; // reset collectionDayOption if new service is selected.
      for (let x = 0; x < this.data2.length - days; x++) {
        let listDate = this.data2[x]["date"].split("-");
        let listTime = this.data2[x]["time"].split(",");

        listTime = listTime[listTime.length - 1].split("-");
        listDate = `${listDate[1]}/${listDate[0]}/${
          listDate[2]
        } ${listTime[1].trim()}`; // mm-dd-yy hh:mm a

        listDate = moment.utc(listDate);

        let collectionDayOptionobj = {
          text: listDate.format("MMM-DD-YYYY"),
          id: this.data2[x]["date"],
        };
        this.collectionDayOption.push(collectionDayOptionobj);
      }
      this.collectionDay = this.collectionDayOption[0].id;
      console.log(this.collectionDay)
      this.getCollectionTime();
    },

    getCollectionTime() {
      // this.deliveryDay = "";
      // this.deliveryTime = "";
      // this.collectionTime = "";
      this.collectionDayOptionValue = "";
      this.collectionTimeOption = [];
      const selectedDate = this.data2.filter(
        (d) => d.date === this.collectionDay
      );
      this.collectionDayOptionValue = selectedDate[0]["time"].split(",");
      // this.collectionTimeOption = selectedDate[0]["time"].split(",");
      for (let i = 0; i < this.collectionDayOptionValue.length; i++) {
        var pickUpArray = [];
        pickUpArray.push(this.collectionDayOptionValue[i].split("-"));
        this.collectionTimeOption.push(this.collectionDayOptionValue[i]);
        if (
          this.selectedServicesName.length != 0 &&
          this.selectedServicesName[0]["service_name"] ==
            "Rush Wash & Fold Deliver"
        ) {
          if (pickUpArray[0][1] == " 05:00 PM") {
            break;
          }
        } else {
          if (pickUpArray[0][1] == " 11:00 PM") {
            break;
          }
        }
      }
      this.collectionTime = this.collectionTimeOption[0];
      this.collectionDayOptionValue = "";
      this.getDeliveryDate();
      // this.collectionTimeDisable = false;
    },

    getDeliveryDate() {
      // this.deliveryTimeDisable = true;
      // this.deliveryDay = "";
      // this.deliveryTime = "";
      //Get delivery date according to collection date
      this.deliveryDayOptions = [];

      //Get maximum drop off duration from serives
      for (let i = 0; i < this.selectedServicesList.length; i++) {
        var tempObj = this.data1.filter(
          (d) => d.serviceId === this.selectedServicesList[i]
        );
        this.dropOffTimeDuration = 0;
        if (this.dropOffTimeDuration < tempObj[0]["dropOffTimeDuration"]) {
          this.dropOffTimeDuration = tempObj[0]["dropOffTimeDuration"].trim();
        }
      }
      // console.log(this.dropOffTimeDuration)
      var index = this.collectionTimeOption.indexOf(this.collectionTime); //Get index of time selected for drop off duration
      var dropoffObject = this.data2.filter(
        (d) => d.date === this.collectionDay
      ); //Get that date object from data2
      var dropOffDurationTimeSlot = dropoffObject[0]["dropOffTime"].split(","); //Get drop off time slots
      // console.log("duration",dropoffObject[0]["dropOffTime"])

      if (
        Number(this.dropOffTimeDuration) <
        Number(dropOffDurationTimeSlot[index].trim())
      ) {
        //Compare greater one
        this.dropOffTimeDuration = dropOffDurationTimeSlot[index].trim(); //Replace
      }
      // console.log(this.dropOffTimeDuration)
      var selectedTime = this.collectionTime.split("-");

      var selectedDate = this.collectionDay.split("-");

      selectedDate =
        selectedDate[1] + "/" + selectedDate[0] + "/" + selectedDate[2]; //Format date in mm-dd-yy

      var dates = selectedDate + " " + selectedTime[1].trim(); // mm-dd-yy hh:mm

      this.minimumDeliveryDate = moment
        .utc(dates)
        .add(this.dropOffTimeDuration, "hours"); //Add drop off duration
      // console.log(this.minimumDeliveryDate)

      this.deliveryDayOptions = [];
      //Now bind dates for deliver
      for (let i = 0; i < this.data2.length; i++) {
        var listDate = this.data2[i]["date"].split("-");
        var listTime = this.data2[i]["time"].split(",");

        listTime = listTime[listTime.length - 1].split("-");

        listDate =
          listDate[1] +
          "/" +
          listDate[0] +
          "/" +
          listDate[2] +
          " " +
          listTime[1].trim(); // mm-dd-yy hh:mm a
        listDate = moment.utc(listDate);
        //Get list of delivery dates

        let deliveryOptionObj = {
          id: this.data2[i]["date"],
          text: listDate.format("MMM-DD-YYYY"),
        };
        if (
          moment.utc(listDate).isAfter(moment.utc(this.minimumDeliveryDate)) ||
          moment.utc(listDate).isSame(moment.utc(this.minimumDeliveryDate))
        ) {
          this.deliveryDayOptions.push(deliveryOptionObj);
        }
      }
      this.deliveryDay = this.deliveryDayOptions[0].id;
      this.getDeliveryTime();
      // this.deliveryDayDisable = false;
    },

    getDeliveryTime() {
      this.deliveryOption = this.dropOffOptions[0];
      this.deliveryTimeOption = [];
      // this.deliveryTime = "";
      const selectedDeliveryObject = this.data2.filter(
        (d) => d.date === this.deliveryDay
      );

      var deliveryTimes = selectedDeliveryObject[0]["time"].split(", ");

      var selectedDeliveryDate = this.deliveryDay.split("-");

      selectedDeliveryDate =
        selectedDeliveryDate[1] +
        "/" +
        selectedDeliveryDate[0] +
        "/" +
        selectedDeliveryDate[2]; //Format date in mm-dd-yy

      for (let i = 0; i < deliveryTimes.length; i++) {
        var timeArray = deliveryTimes[i].split("-");
        // console.log(timeArray[1])
        var dates = selectedDeliveryDate + " " + timeArray[0].trim(); // mm-dd-yy hh:mm
        //Get list of delivery times
        if (
          moment.utc(dates).isAfter(moment.utc(this.minimumDeliveryDate)) ||
          moment.utc(dates).isSame(moment.utc(this.minimumDeliveryDate))
        ) {
          this.deliveryTimeOption.push(deliveryTimes[i]);
          if (
            this.selectedServicesName.length != 0 &&
            this.selectedServicesName[0]["service_name"] ==
              "Rush Wash & Fold Deliver"
          ) {
            if (timeArray[1] == " 12:00 AM") {
              break;
            }
          } else {
            if (timeArray[1] == " 11:00 PM") {
              break;
            }
          }
        }
      }
      this.deliveryTime = this.deliveryTimeOption[0];

      // this.deliveryTimeDisable = false;
    },
    validateForm(form) {
      if (form == "postcode") {
        //Enable address field if postocode entered and valid
        if (this.postCode.length >= 6) {
          return !this.isValid;
          //return false;
        } else {
          return true;
        }
      } else if (form == "address") {
        //Enable Next button if post code and address available

        if (this.postCode != "" && this.Address1 != "" && this.isValid) {
          return false;
        } else {
          return true;
        }
      }else if (form == "collection") {
        
        if (
          this.collectionDay.length != 0 &&
          this.collectionTime.length != 0 &&
          this.collectionOption.length != 0 &&
          this.deliveryDay.length != 0 &&
          this.deliveryTime.length != 0 &&
          this.deliveryOption.length != 0
        ) {
          return false;
        } else {
          return true;
        }
      } else if (form == "services") {
        if (
          this.selectedServicesList != null &&
          this.selectedServicesList.length > 0
        ) {
          return false;
        } else {
          return true;
        }
      }  else if (form == "personal") {
        if (
          this.Firstname != "" &&
          this.Lastname != "" &&
          this.Email != "" &&
          (this.MobileNo.length == 11 || this.MobileNo.length == 10)
        ) {
          if (this.ReferFriendCode.length > 0 && this.voucherValid) {
            return false;
          } else if (this.ReferFriendCode.length > 0 && !this.voucherValid) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    },

    showhide(id) {
      // eslint-disable-next-line no-extra-semi
      (this.addressDiv = false),
        (this.servicesDiv = false),
        (this.collectionDiv = false),
        (this.deliveryDiv = false),
        (this.personalDiv = false),
        (this.paymentDiv = false);
      this.isLoginDiv = false;

      if (id == "1") {
        this.addressDiv = true;
        this.activeStep = 1;
        this.isLoginDiv = false;
      } else if (id == "2") {
        if (this.isLogin != true && this.isLogin != "true") {
          this.isLoginDiv = true;
          document.getElementById("LoginClick").click();

          //document.getElementById('LoginClick').click();
        } else {
          if (!this.validateForm("address")) {
            this.$store.commit("setAddress", this.Address1);
            this.isLoginDiv = false;
            this.servicesDiv = true;
            this.activeStep = 2;
          } else {
            this.addressDiv = true;
            this.activeStep = 1;
            this.isLoginDiv = false;
          }
        }
        if (
          this.totalOrder == 0 &&
          (this.isLogin == true || this.isLogin == "true")
        ) {
          document.getElementById("instructionsClick").click();
        }
      } else if (id == "3") {
        this.collectionDiv = true;
        this.deliveryDiv = true;
        this.activeStep = 3;
        this.getCollectionDate();
      } else if (id == "4") {
        this.personalDiv = true;
        this.activeStep = 4;
      } else if (id == "5") {
        this.paymentDiv = true;
      }

      window.scrollTo(0, 0);
    },

    addRemoveServices(serviceId, name) {
      const temp = this.data1;

      //Remove servicename if already exists
      for (let x = 0; x < this.selectedServicesName.length; x++) {
        if (this.selectedServicesName[x]["service_id"] == serviceId) {
          this.selectedServicesName.splice(x, 1);
        }
      }

      // remove service from the list
      if (this.selectedServicesList.indexOf(serviceId) >= 0) {
        this.selectedServicesList.splice(
          this.selectedServicesList.indexOf(serviceId),
          1
        );

        //Remove from temporary selected serviceSubService List also
        for (
          let i = 0;
          i < this.tempSelectedServiceSubServiceList.length;
          i++
        ) {
          if (
            this.tempSelectedServiceSubServiceList[i]["service_id"] == serviceId
          ) {
            var old_subservice =
              this.tempSelectedServiceSubServiceList[i]["subservice_id"];

            this.tempSelectedSubServiceList.splice(
              this.tempSelectedSubServiceList.indexOf(old_subservice),
              1
            );

            this.tempSelectedServiceSubServiceList.splice(i, 1);
          }
        }

        //Remove from selectedServiceSubServiceList also

        for (let i = 0; i < this.selectedServiceSubServiceList.length; i++) {
          if (
            this.selectedServiceSubServiceList[i]["service_id"] == serviceId
          ) {
            old_subservice =
              this.selectedServiceSubServiceList[i]["subservice_id"];

            this.selectedSubServicesList.splice(
              this.selectedSubServicesList.indexOf(old_subservice),
              1
            );

            this.selectedServiceSubServiceList.splice(i, 1);
          }
        }
      }
      //Add service to list
      else {
        let v = this;
        if (this.selectedServicesList.length > 0) {
          v.selectedServicesList = [];
          v.selectedServicesList.push(serviceId);
        } else {
          this.selectedServicesList.push(serviceId);
        }
        this.inService = true;
        this.selectedServices = temp.filter((d) => d.serviceId === serviceId);
        // console.log(this.selectedServices[0].serviceName)
        // console.log(this.selectedServices)

        if (
          this.selectedServices[0].subServiceList &&
          this.selectedServices[0].subServiceList.length
        ) {
          var subservice_item = {
            service_id: serviceId,
            subservice_id:
              this.selectedServices[0].subServiceList[0]["subServiceId"],
            subservice_name:
              this.selectedServices[0].subServiceList[0]["serviceName"].split(
                " "
              ),
          };

          this.tempSelectedServiceSubServiceList.push(subservice_item);

          this.selectedServiceSubServiceList.push(subservice_item);

          this.selectedSubServicesList.push(
            this.selectedServices[0].subServiceList[0]["subServiceId"]
          );
          this.tempSelectedSubServiceList.push(
            this.selectedServices[0].subServiceList[0]["subServiceId"]
          );

          var service_item = {
            service_id: serviceId,
            service_name: name + "-" + subservice_item.subservice_name[0],
          };

          this.selectedServicesName.push(service_item);
        } else {
          var service_items = {
            service_id: serviceId,
            service_name: name,
          };
          let v = this;
          if (this.selectedServicesName.length > 0) {
            v.selectedServicesName = [];
            v.selectedServicesName.push(service_items);
          } else {
            this.selectedServicesName.push(service_items);
          }
        }
      }
    },

    addRemoveSubServices(subServiceId) {
      //Remove/Add from temporary subservice List

      for (let i = 0; i < this.tempSelectedServiceSubServiceList.length; i++) {
        if (
          this.tempSelectedServiceSubServiceList[i]["service_id"] ==
          this.selectedServices[0]["serviceId"]
        ) {
          var old_subservice =
            this.tempSelectedServiceSubServiceList[i]["subservice_id"];
          this.tempSelectedSubServiceList.splice(
            this.tempSelectedSubServiceList.indexOf(old_subservice),
            1
          );

          var subServiceObject = this.selectedServices[0][
            "subServiceList"
          ].filter((d) => d.subServiceId === subServiceId);

          this.tempSelectedServiceSubServiceList[i]["subservice_id"] =
            subServiceId;
          this.tempSelectedServiceSubServiceList[i]["subservice_name"] =
            subServiceObject[0]["serviceName"].split(" ");

          this.tempSelectedSubServiceList.push(subServiceId);
        }
      }
    },

    SaveSubService() {
      this.selectedSubServicesList = this.tempSelectedSubServiceList;
      this.selectedServiceSubServiceList =
        this.tempSelectedServiceSubServiceList;

      for (let x = 0; x < this.selectedServicesName.length; x++) {
        if (
          this.selectedServicesName[x]["service_id"] ==
          this.tempSelectedServiceSubServiceList[0]["service_id"]
        ) {
          var name = this.selectedServicesName[x]["service_name"].split("-");

          name =
            name[0] +
            "-" +
            this.tempSelectedServiceSubServiceList[0]["subservice_name"][0];

          this.selectedServicesName[x]["service_name"] = name;
        }
      }
      this.tempSelectedSubServiceList = [];
      this.tempSelectedServiceSubServiceList = [];
    },
    restoreOldService() {
      this.tempSelectedServiceSubServiceList = this.selectedSubServicesList;
      this.tempSelectedServiceSubServiceList =
        this.selectedServiceSubServiceList;
    },

    async verifyVoucher() {
      this.showloading = true;
      const userData = this.$store.getters.getUser;
      const response = await VerifyReferFriendCode.postVerifyReferFriendCode({
        customerId: Number(decryptAES(userData["id"])),
        referCode: this.ReferFriendCode,
      });

      if (response) {
        if (response.data.isSuccess) {
          this.voucherValid = true;
          this.isReferCode = response.data.data1["isReferCode"];
          this.isOfferCode = response.data.data1["isOfferCode"];
          this.voucherText =
            "<span class='text-black'> <b> *Voucher Valid </b> </span>";
        } else {
          this.voucherValid = false;
          this.isReferCode = false;
          this.isOfferCode = false;
          this.voucherText =
            "<span class='text-danger'> <b> *" +
            response.data.message +
            "</b> </span>";
        }
      } else {
        if (localStorage.getItem("TokenExpire")) {
          const response = await TokenExpireLogin.tokenExpireLogin();
          if (response.data.isSuccess) {
            this.verifyVoucher();
          }
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      }

      this.showloading = false;
    },

    async insertOrder() {
      this.showloading = true;

      var isLogin = localStorage.getItem("isLogin");

      if (isLogin == null || isLogin == "false") {
        if (!this.$session.exists()) {
          this.$session.start();
        }

        var userObjectSession = {
          firstname: this.Firstname,
          lastname: this.Lastname,
          email: this.Email,
          contactNo: this.MobileNo,
          postcode: this.postCode,
          address: this.Address1,
          Address2: this.Address2,
        };

        this.$session.set("userObject", userObjectSession);
        document.getElementById("LoginClick").click();
      } else {
        const userData = this.$store.getters.getUser;

        this.userId = Number(decryptAES(userData["id"]));

        var pickupDate = this.collectionDay.split("-");

        pickupDate =
          pickupDate[2] +
          "-" +
          pickupDate[1] +
          "-" +
          pickupDate[0] +
          "T00:00:00"; //Format date in yy-mm-dd

        var dropoffDate = this.deliveryDay.split("-");
        dropoffDate =
          dropoffDate[2] +
          "-" +
          dropoffDate[1] +
          "-" +
          dropoffDate[0] +
          "T00:00:00"; //Format date in yy-mm-dd
        var offerCode = "";
        var referCode = "";
        if (this.isOfferCode) {
          offerCode = this.ReferFriendCode;
        } else if (this.isReferCode) {
          referCode = this.ReferFriendCode;
        }
        const insertResponse = await OrderNow.InsertOrder({
          specialInstruction: this.specialInstructions,
          customerId: this.userId,
          preAmount: 30.0,
          pickupDate: pickupDate,
          pickupTime: this.collectionTime,
          dropOffDate: dropoffDate,
          dropOffTime: this.deliveryTime,
          deliveryNotes: this.deliveryNotes,
          Services: this.selectedServicesList.join(),
          SubServices: this.selectedSubServicesList.join(),
          collectionOption: this.collectionOption,
          deliveryOption: this.deliveryOption,

          orderAddress: {
            firstname: this.Firstname,
            lastname: this.Lastname,
            email: this.Email,
            contactNo: this.MobileNo,
            postCode: this.postCode,
            addressLine1: this.Address1,
            addressLine2: this.Address2,
            plateform: "Web",
          },
          useReferCode: referCode,
          offerCode: offerCode,
        });
        // console.log(insertResponse);
        if (insertResponse) {
          if (insertResponse.data.isSuccess) {
            localStorage.setItem(
              "OrderId",
              this.$CryptoJS.AES.encrypt(
                insertResponse.data.data1["id"].toString(),//orderId
                Vue.prototype.$secretKey
              ).toString()
            );

            localStorage.setItem(
              "CustomerId",
              this.$CryptoJS.AES.encrypt(
                this.userId.toString(),
                Vue.prototype.$secretKey
              ).toString()
            );

            // localStorage.setItem(
            //   "sessionId",
            //   this.$CryptoJS.AES.encrypt(
            //     insertResponse.data.data1["sessionId"],
            //     Vue.prototype.$secretKey
            //   ).toString()
            // );

            localStorage.setItem(
              "TokenId",
              JSON.stringify(insertResponse.data.token) //.data1["tokenId"]
            );

            localStorage.setItem(
              "SessionExpireToken",
              JSON.stringify(insertResponse.data.cusip) //data2
            );

            this.$router.push({ name: "checkout" });
            // await checkout.checkout(insertResponse.data.data1["sessionId"]);
          } else {
            this.$notify.error({
              title: "Oops",
              message: "Something went wrong",
            });
          }
        } else {
          if (localStorage.getItem("TokenExpire")) {
            const response = await TokenExpireLogin.tokenExpireLogin();
            if (response.data.isSuccess) {
              this.insertOrder();
            }
          } else {
            this.$notify.error({
              title: "Oops",
              message: "Something went wrong",
            });
          }
        }
      }

      this.showloading = false;
    },
  },
};
</script>

<style scoped>
section.container-fluid {
  padding: 0px 0px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 100px;
}

::v-deep .el-step__head.is-process {
  color: #c0c4cc !important;
  border-color: #c0c4cc !important;
}

::v-deep .el-step__title.is-process {
  font-weight: 100 !important;
  color: #c0c4cc !important;
}

::v-deep .el-step__title.is-finish {
  color: #09285b !important;
}

.tag {
  border: 2px dashed #ddd;
  padding: 5px 10px;
  transition: all ease 0.3s;
}

.el-step__icon.is-text,
.el-step__head.is-finish {
  color: #e66031;
  border-color: #e66031;
}

.grid {
  display: grid;
}

.gap-2 {
  gap: 0.5rem;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.h-20 {
  height: 120px;
}

@media (min-width: 377px) {
  .h-20 {
    height: 82px;
  }
}

@media (min-width: 576px) {
  .h-20 {
    height: 120px;
  }
}

@media (max-width: 377px) {
  .h-20 {
    height: 60px;
  }
}

@media (max-width: 377px) {
  .text-sm {
    font-size: 14px;
    line-height: 1.25rem;
  }
}
</style>

<style>
.select2-container--default.select2-container--disabled
  .select2-selection--single {
  border: 1px solid #dcdfe6;
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  box-shadow: 0 0 20px rgba(219, 224, 234, 0.6);
}

@media (max-width: 1024px) {
  .pl-15 {
    padding-left: 15px !important;
    text-align: center;
    margin-bottom: 15px;
  }
  .margin-top {
    margin-top: 0px !important;
  }

  .margin-bottom {
    margin-bottom: 0px;
  }
}
</style>
