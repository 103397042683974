import Vue from "vue";
import Login from "@/services/Login";
import store from "../store/index";

export default {
  async tokenExpireLogin() {
    let userData = store.getters.getUser;
    let email = Vue.CryptoJS.AES.decrypt(
      userData.email,
      Vue.prototype.$secretKey
    ).toString(Vue.CryptoJS.enc.Utf8);

    let password = Vue.CryptoJS.AES.decrypt(
      userData.password,
      Vue.prototype.$secretKey
    ).toString(Vue.CryptoJS.enc.Utf8);

    const response = await Login.getLogin({
      username: email,
      password: password,
    });
    if(response.data.isSuccess)
    {
      const token=response.data.data1
      const encryptedToken = Vue.CryptoJS.AES.encrypt(
        token,
        Vue.prototype.$secretKey
      ).toString();
      store.commit("setToken",encryptedToken)
      return response;
    }
    
  },
};
