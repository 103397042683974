<template>
  <div>
    <!-- Back To Top -->
    <div id="backTop">
      <i aria-hidden="true" class="fa fa-arrow-up"></i>
    </div>

    <Toolbar />

    <nav
      class="et-navbar sticky-top"
      style="box-shadow: 0px 0px 2px"
      id="navbar"
    >
      <div class="container-fluid mt-2 position-relative"> <!--container mt-2 position-relativ-->
        <div class="row justify-content-md-center"><!--row pl-4 pr-4 p-sm-0 justify-content-between-->
          <div class="logo col-md-8 col-lg-3">
            <a :href="websiteLink" class="d-inline-block"
              ><img src="static/images/logo.png" alt="Smart Laundry- Logo"
            /></a>
          </div>
         
      <!-- Sidebar Widget -->
      <div class="side-widget col-lg-6 p-0 right" >
        <div class="widget-content">
          <div
            class="order pt-3 pr-3 d-inline-flex align-items-center"
            style="font-size: 18px; border-bottom: initial"
          >
            <!-- <i class="rounded-pill mr-1 icon-bg bg-white">
              <img
                src="/static/images/shopping-cart.svg"
                class="f-icon"
                alt="My Orders-Smart Laundry"
              />
            </i> -->
            <router-link to="/Orders" v-if="isLogin"
              ><a class="text-nav pl-1">
                My Orders</a
              ></router-link
            >
            <a v-else class="text-nav pl-1" data-toggle="modal"
              data-target="#loginModal">
                My Orders</a
              >
          </div>
          <div
            class="order pt-3 pr-3 d-inline-flex align-items-center"
            style="font-size: 18px; border-bottom: initial"
          >
            <!-- <i class="rounded-pill mr-1 icon-bg bg-white">
              <img
                src="/static/images/user (2).svg"
                class="f-icon"
                alt="My Account-Smart Laundry"
              />
            </i> -->
            <router-link to="/Account" v-if="isLogin"
              ><a class="text-nav pl-1" 
                >Account</a
              ></router-link
            >
            <a v-else class="text-nav pl-1" data-toggle="modal"
              data-target="#loginModal"
                >Account</a
              >
          </div>
            <div
            class="order pt-3 pr-3 d-inline-flex align-items-center"
            style="font-size: 18px; border-bottom: initial"
          >
            <!-- <i class="rounded-pill mr-1 icon-bg bg-white">
              <img
                src="/static/images/lock.svg"
                class="f-icon"
                alt="Change Password-Smart Laundry"
              />
            </i> -->
            <router-link to="/ChangePassword" v-if="isLogin"
              ><a class="text-nav pl-1"
                >Change Password</a
              ></router-link
            >
            <a v-else class="text-nav pl-1" data-toggle="modal"
              data-target="#loginModal"
                >Change Password</a
              >
          </div>
          <div
            class="order pt-3 pr-3 d-inline-flex align-items-center"
            style="font-size: 18px; border-bottom: initial"
          >
            <router-link to="/Pricing"
              ><a class="text-nav pl-1"
                >Pricing</a
              ></router-link
            >
          </div>
          <div
              class="b-drop float-right ml-lg-4 ml-2 position-relative d-flex"
            >
              

              <router-link to="/"
                ><a
                  class="btn shadow bg-yellow text-capitalize text-white"
                  v-if="$route.name != 'BookNow'"
                  >Place Order</a
                ></router-link
              >
            </div>
           </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- Mobile Menu -->
    <nav id="et-mobile-nav">
      <ul>
        <li>
          <router-link v-if="isLogin" to="/Account">Account </router-link>
          <a v-else data-toggle="modal" data-target="#loginModal">Account</a>
        </li>
        <li>
          <router-link v-if="isLogin" to="/Orders">Orders</router-link>
          <a v-else data-toggle="modal" data-target="#loginModal">Orders</a>
        </li>
        <li>
          <router-link v-if="isLogin" to="/ChangePassword">Change Password</router-link>
          <a v-else data-toggle="modal" data-target="#loginModal">Change Password</a>
        </li>
        <li>
          <router-link to="/Pricing">Pricing</router-link>
          <a>Pricing</a>
        </li>
        <li>
          <router-link v-if="$route.name != 'BookNow'" to="/">Place Order</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Toolbar from "@/core/toolbar.vue";

export default {
  name: "navigation2",
  components: {
    Toolbar,
  },
  data() {
    return {
      isLogin: false,
      websiteLink: this.$website,
    };
  },
  mounted() {
    if(localStorage.getItem("isLogin")==true||localStorage.getItem("isLogin")=='true')
     {
       this.isLogin=true;
     }
  },
};
</script>
<style>
.right{
 text-align: right;
}
@media screen and (max-width: 1000px){
  .right{
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .side-widget{
    display: none;
  }
}
</style>