<template>
  <footer class="container-fluid et-footer">
    <!-- <div class="container"> -->
    <div class="row align-items-center justify-content-center">
      <!-- Social Media -->
      <div class="col-md-3 pt-4">
        <div class="footer-social">
          <div>
            <label class="text-uppercase text-white"
              >FOLLOW US ON SOCIAL MEDIA</label
            >
          </div>
          <div>
            <a
              href="https://www.facebook.com/smartlaundryphoenix/"
              target="_blank"
              rel="noreferrer noopener"
              class="d-inline-block rounded-pill fb-icon mr-2"
              ><i class="fab fa-facebook"></i
            ></a>
            <a
              href="https://www.twitter.com/smartlaundryphx"
              target="_blank"
              rel="noreferrer noopener"
              class="d-inline-block rounded-pill tw-icon mr-2"
              ><i class="fab fa-twitter"></i
            ></a>
            <a
              href="https://www.instagram.com/smartlaundryllc"
              target="_blank"
              rel="noreferrer noopener"
              class="d-inline-block rounded-pill ig-icon mr-2"
              ><i class="fab fa-instagram"></i
            ></a>
          </div>
        </div>
      </div>
      <!-- Contact info -->
      <div class="col-md-3 pt-4">
        <div class="contact-info align-items-center">
          <div>
            <label class="text-uppercase text-white">CONTACT US</label>
          </div>

          <div>
            <div
              class="meta mb-2 text-left d-flex align-items-center pr-1 pl-1"
            >
              <i class="d-inline-block mr-2 fas fa-clock text-white"> </i>
              <p class="align-bottom text-white d-inline-block m-0">
                7 days a week 7:00 AM to 11:00 PM
              </p>
            </div>
            <div class="meta text-left d-flex align-items-center pr-1 pl-1">
              <i class="d-inline-block mr-2 fas fa-envelope text-white"> </i>
              <p class="align-bottom text-white d-inline-block m-0">
                <a
                  href="mailto:support@smartlaundry.com"
                  style="text-decoration: none; color: white"
                  >support@smartlaundry.com</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3 pt-4">
        <div class="footer-social">
          <div><label class="text-uppercase text-white">PAYMENTS</label></div>
          <div>
            <img
              src="/static/images/visa_logo.png"
              alt="logo"
              class="img-fluid mr-2"
              style="width: 20%"
            />
            <img
              src="/static/images/american_express_logo.png"
              alt="logo"
              class="img-fluid mr-2"
              style="width: 20%"
            />
            <img
              src="/static/images/mastercard.svg"
              alt="logo"
              class="img-fluid mr-2"
              style="width: 20%"
            />
            <img
              src="/static/images/apple-pay.svg"
              alt="logo"
              class="img-fluid mr-2"
              style="width: 20%"
            />
          </div>
        </div>
      </div>
    </div>
    <hr class="border-t border-lightgray mt-4 mb-4" />

    <p class="small text-white mt-4 mb-0 text-center">
      &copy; {{ new Date().getFullYear() }} All Rights Reserved. Designed by
      <a
        href="https://arkinfosoft.com/"
        target="_blank"
        rel="noopener noreferrer"
        class="text-white"
        >ARK Infosoft</a
      >
    </p>
    <p class="small text-white mb-0 text-center">
      <a href="/terms-conditions" style="color: white">Terms & Conditions</a> &
      <a href="/privacy-policy" style="color: white">Privacy Policy</a>
    </p>
    <!-- </div> -->
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      websiteLink: this.$website,
    };
  },
};
</script>

<style scoped>
.border-t {
  border-top-width: 1px;
  border-style: solid;
}

.border-lightgray {
  border-color: rgb(211, 209, 209);
}
</style>
