<template>
  <div>
    <div class="loaderss" v-if="showloading"></div>
  </div>
</template>

<script>
import Vue from "vue";
import Checkout from "@/services/checkout";
export default {
  name: "Services",
  data() {
    return {
      showloading: true,
    };
  },
  mounted() {
    // this.confirmPayment() //sessionId
    if (localStorage.getItem("TokenId") != null) {
      this.confirmPayment();
    } else {
      this.$router.push("Orders");
    }  
  },
  methods: {
    async confirmPayment() {
      const response = await Checkout.confirmPayment({
        sessionId: Vue.CryptoJS.AES.decrypt(
          localStorage.getItem("TokenId"),//sessionId
          Vue.prototype.$secretKey
        ).toString(Vue.CryptoJS.enc.Utf8),
        orderId: Number(
          Vue.CryptoJS.AES.decrypt(
            localStorage.getItem("OrderId"),
            Vue.prototype.$secretKey
          ).toString(Vue.CryptoJS.enc.Utf8)
        ),
      });
      console.log(response);
      this.showloading = false;
      localStorage.setItem("OrderResponse", JSON.stringify(response.data));
      if (response.data.isSuccess) {
        this.$store.commit("setTotalOrder");
      } else {
        this.$notify.error({
          title: "Oops",
          message: "Could not process your order.",
        });
      }

      localStorage.removeItem("TokenId");//sessionId
      localStorage.removeItem("OrderId");
      this.$router.push("Orders");
    },
  },
};
</script>
