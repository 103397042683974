<template>
  <section class="container-fluid et-add-restaurant position-relative">
    <!-- <div class="loaderss" v-if="showloading"></div> -->
    <div class="row justify-content-md-center m-0">
      <!-- <customer-navigation /> -->
      <div class="col-md-8 overflow-hidden" style="margin-top: 2%">
        <div class="row justify-content-center">
          <!-- Account -->
          <div class="col-md-10 section-heading text-center mt-4">
            <h2>Privacy <span class="text-yellow">Policy</span></h2>
          </div>
        </div>
        <div class="w-100 mt-3">
          <div class="content-group">
            <div class="para">
              <p>
                SmartLaundry.com are committed to the protection of private
                information and ensuring compliance with all relevant privacy
                laws.
              </p>
              <p>
                The terms of this Privacy Policy apply to all users of our
                websites and apps. Please read carefully and if you do not agree
                with the terms of this policy, you should immediately
                discontinue the use of our websites or apps and contact us so we
                can remove your details from our database.
              </p>
              <p>
                In this policy, you will find answers to some questions about
                how and why we collect, use, and share your personal
                information.
              </p>
            </div>
            <div class="list">
              <div class="box">
                <h3>1. Information we collect</h3>
                <p>
                  We collect some information from you to deliver key functions
                  of our business including;
                </p>
                <p>
                  • Your Name – so we know who you are;<br />
                  • Your E-mail address – so we can communicate with you on our
                  services;<br />
                  • Your Phone – so that we can speak with you on our
                  services;<br />
                  • Your Postal Code and State – so that we can geographically
                  prioritise the delivery of our services;
                </p>
                <p>
                  So our clients can segment or select a service of our laundry
                  service based these attributes if necessary. These details are
                  not shared with anyone outside of SmartLaundry and are
                  de-identified from you when the client is segmenting or
                  selecting a service.
                </p>
                <p>
                  If you create an account with us (through the web application
                  or any of our other applications) we collect further details
                  including your password and account preferences to keep your
                  account secures and to enable us to give you the services that
                  you want, the way you want them.
                </p>
                <p>
                  We may send notifications via E-mail, SMS or Phone Call to
                  deliver our services.
                </p>
                <p>
                  We won’t ask you to provide us with any sensitive personal
                  information (e.g. information relating to your physical or
                  mental health, race or ethnicity, trade union membership,
                  sexual orientation, political opinions, religious or
                  philosophical beliefs, and/or criminal allegations or
                  convictions)
                </p>
              </div>
              <div class="box">
                <h3>2. What we do with the information</h3>
                <p>
                  We may use your information to;<br />
                  • Administer your account if you have registered with us;<br />
                  • Provide you with information on SmartLaundry opportunities
                  relevant to you;<br />
                  • To communicate SmartLaundry news;<br />
                </p>
              </div>
              <div class="box">
                <h3>3. Whom we share the information with</h3>
                <p>
                  We do not share this information with any 3rd parties outside
                  of the SmartLaundry family and the only sharing of information
                  will occur.<br />
                </p>
              </div>
              <div class="box">
                <h3>4. How you can stop receiving communications</h3>
                <p>
                  If you no longer want to receive communications from us please
                  notify us in writing at smartlaundryphoenix@gmail.com with
                  your email address and your full name.
                </p>
              </div>
              <div class="box">
                <h3>
                  5. How to correct, update or request removal of your
                  information
                </h3>
                <p>
                  We rely on information that you give us so it is important to
                  keep those details up to date and accurate. If personal
                  information changes, please notify us in writing at
                  smartlaundryphoenix@gmail.com with your email address and your
                  full name and any relevant changes or you can update it
                  yourself through your account if you create an account with us
                  (through the web application or any of our other
                  applications).
                </p>
              </div>
              <div class="box">
                <h3>
                  6. How we protect the security of your personal information
                </h3>
                <p>
                  We take the security of your information very seriously and we
                  use appropriate measures to store all of the personal
                  information that we have collected in a secure, controlled
                  environment to prevent unauthorised access.
                </p>
                <p>
                  You are also responsible for keeping any account you have with
                  us secure, so where we have given you(or where you have
                  chosen) a password to access certain parts of our websites or
                  apps, it is important that you keep that password
                  confidential, so please do not to share it with anyone.
                  Unfortunately, the transmission of information via the
                  internet is not completely secure. Because of this, although
                  we will do our best to protect your personal information, we
                  cannot guarantee the security of the data transmitted to our
                  sites or app. So when you submit your personal information
                  through our websites or apps, this is at your own risk.
                </p>
              </div>
              <div class="box">
                <h3>7. Our link to apps and other sites</h3>
                <p>
                  Our websites and apps may contain links to other websites that
                  may not be owned or operated by SmartLaundry. We cannot
                  control nor is it responsible for the privacy practices or
                  content of these other websites. SmartLaundry encourages you
                  to read the privacy statements of every website that collects
                  personally identifiable information. This Privacy Policy
                  applies only to the personal information SmartLaundry collects
                  on its websites and apps.
                </p>
              </div>
              <div class="box">
                <h3>8. Updates to our privacy policy</h3>
                <p>
                  We notify you of any updates to this policy on our website's
                  homepage so that you are always aware of any changes we have
                  made to what personal information we collect, how we use it,
                  and under what circumstances we disclose it. You may be
                  required to read and accept the new terms to continue your use
                  of our website or apps. If we decide to use your personal
                  information in a considerably different way to those that we
                  have previously told you about, we will notify you by e-mail
                  to the e-mail address you have provided. You will then be
                  given a choice as to whether or not you are happy for your
                  personal information to be used in this new way.
                </p>
              </div>
              <div class="box">
                <h3>9. Children’s privacy on our site and apps</h3>
                <p>
                  We take protecting the privacy of all of our customers very
                  seriously and we are especially careful in relation to any
                  communications with children. It is important to us that
                  children can enjoy our websites and apps responsibly. Our
                  policy is not to collect personal information from children
                  under the age of 13. If you are under 13, you are only allowed
                  to use our websites or apps with your parents’ or guardian’s
                  consent.
                </p>
                <p>
                  We encourage parents to regularly monitor and supervise their
                  children's online activities, by using parental control tools,
                  for example. If you are a parent and have any concerns about
                  the collection or use of your child's personal information
                  then please contact us using the contact details below.
                </p>
              </div>
              <div class="box">
                <h3>10. Contacting us</h3>
                <p>
                  If you have any questions about our Privacy Policy or the use
                  of your personal information please contact
                </p>
                <p>
                  SmartLaundry.com<br />
                  5108 N. 19th Avenue<br />
                  Phoenix, Arizona 85015<br />
                  smartlaundryphoenix@gmail.com<br />
                  (602) 549-9920
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style>
.para p {
  padding-top: 5px;
  padding-bottom: 5px;
}
.box{
    padding-top:10px;
    padding-bottom: 10px;
}
</style>
