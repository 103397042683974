<template>
  <div>
    <div class="loaderss" v-if="showloading"></div>
    <!-- Welcome -->
    <section
      class="container-fluid et-welcome about"
      style="padding-bottom: 0px"
    >
      <div class="container">
        <div class="row" style="margin-top: 4%">
          <div class="col-sm-12 text-center mb-4">
            <h2>Pricing</h2>
          </div>

          <div class="alert alert-danger col-sm-12" role="alert" v-if="isError">
            <h4 class="alert-heading">Oops!</h4>
            <p>
              Errr..Looks like things are not going in our favour! Error occured
            </p>
          </div>

          <!-- On small devices -->
          <div
            class="col-6 d-lg-none"
            v-for="item in data1"
            :key="item.serviceId"
            v-on:click="showPriceList(item.serviceId)"
          >
            <div
              class="mb-4 bg-white p-2 row mr-0 ml-0"
              :id="item.serviceId"
              v-bind:class="[
                activeId == item.serviceId ? 'border-blue' : 'border-white',
              ]"
            >
              <div class="col-4 pl-0 pr-0 d-flex align-items-center">
                <img
                  :src="baseUrl + item.serviceIcon"
                  :alt="item.serviceName"
                  class="img-fluid"
                  style="width: 80%"
                />
              </div>

              <div class="col-8 pl-0 pr-0">
                <a
                  class="
                    text-reset text-capitalize
                    position-relative
                    d-inline-block
                    text-decoration-none
                    bg-white
                  "
                  >{{ item.serviceName }}</a
                >
              </div>
            </div>
          </div>

          <!-- On large devices-->
          <ul class="welcome-features d-none d-lg-block" v-if="data1.length">
            <li
              v-for="serviceItem in data1"
              v-bind:key="serviceItem.serviceId"
              class="marg"
              style="border-radius: 50px"
              v-bind:class="[
                activeId == serviceItem.serviceId
                  ? 'border-blue'
                  : 'border-black',
              ]"
              v-on:click="showPriceList(serviceItem.serviceId)"
            >
              <a>{{ serviceItem.serviceName }}</a>
            </li>
          </ul>

          <!-- Introduction of Services -->

          <div class="col-sm-12">
            <div class="productBox mb-0">
              <div
                class="productImg d-flex align-items-center"
                style="border-radius: 0px"
              >
                <img
                  :src="baseUrl + activeServiceIcon"
                  :alt="activeServiceName"
                  style="border-radius: 0px"
                />
              </div>
              <div
                class="productData section-heading"
                style="text-align: center"
              >
                <h2>{{ activeServiceName }}</h2>
                <p>{{ activeServiceDescription }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Welcome -->

    <!-- Main Content Area -->
    <section class="et-content container-fluid padding-top">
      <div class="container">
        <div class="row">
          <!-- Content Area -->
          <div class="col-lg-8 mt-5 margin-top">
            <!-- Sub Services -->
            <div
              class="content-widget col-sm-12 pb-5"
              v-if="activeSubServiceList.length"
            >
              <div class="content-heading position-relative mb-3">
                <h6
                  class="
                    text-yellow
                    position-relative
                    m-0
                    text-capitalize
                    border
                    d-inline-block
                    rounded
                    p-3
                    bg-white
                    small
                    font-weight-bold
                  "
                >
                  Preference For Wash
                </h6>
              </div>
              <div class="content-box row">
                <div class="popular-list-items col-sm-12">
                  <div
                    class="popular-item shadow-box mt-5"
                    v-for="subServiceItem in activeSubServiceList"
                    :key="subServiceItem.subServiceId"
                  >
                    <div class="d-sm-flex align-items-center">
                      <div class="text-center pr-sm-3">
                        <img
                          :src="baseUrl + subServiceItem.serviceIcon"
                          class="d-block m-auto"
                          :alt="subServiceItem.serviceName"
                          style="height: 120px"
                        />
                      </div>
                      <div class="content w-100">
                        <h4 class="mt-2 mb-3">
                          <a
                            class="
                              text-reset text-capitalize text-decoration-none
                              link-hover
                            "
                            >{{ subServiceItem.serviceName }}</a
                          >
                        </h4>
                        <p class="description">
                          {{ subServiceItem.description }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="popular-item mt-5 pl-0">
                    <div class="d-sm-flex align-items-center">
                      <div class="content w-100">
                        <h4 class="font-weight-bold mb-3">
                          <a
                            class="
                              text-reset text-capitalize text-decoration-none
                              link-hover
                            "
                            >Not sure how much you have?</a
                          >
                        </h4>
                        <p class="description">
                          If in doubt – Don’t worry, our driver carry a scale
                          and can help you weight your clothes before they
                          collect them.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Price List -->
            <div class="content-widget col-sm-12" v-if="activePriceList.length">
              <!--|| offerList.length-->
              <div class="content-heading position-relative mb-3">
                <h6
                  class="
                    text-yellow
                    position-relative
                    m-0
                    text-capitalize
                    border
                    d-inline-block
                    rounded
                    p-3
                    bg-white
                    small
                    font-weight-bold
                  "
                >
                  Pricing
                </h6>
              </div>

              <div class="content-box">
                <div class="cuisine-list">
                  <!-- Item Offers -->
                  <div
                    class="d-sm-block d-lg-flex item align-items-center mt-4"
                  >
                    <!-- Small screen -->
                    <!-- <div
                      class="row d-lg-none d-md-none"
                      v-if="offerList.length"
                    > -->
                    <!-- <div class="col-5 pr-0">
                        <div class="bg-white p-2 row mr-0 ml-0">
                          <div
                            class="
                              pl-0
                              pr-0
                              d-flex
                              align-items-center
                              position-relative
                              mr-sm-3
                              bg-white
                              p-2
                            "
                          >
                            <img
                              src="static/images/icons/bestoffer_icon.png"
                              alt="img"
                              class="img-fluid"
                              style="width: 70%"
                            />
                          </div>
                        </div>
                      </div> -->

                    <!-- <div
                        class="
                          col-7
                          pl-0
                          d-flex
                          align-items-center
                          productData
                          section-heading
                        "
                      >
                        <h2>Offers</h2>
                      </div> -->
                    <!-- </div> -->

                    <!-- <div
                      class="
                        img-thumbnail
                        position-relative
                        mb-4 mb-sm-0
                        mr-sm-3
                        bg-white
                        p-2
                        d-none d-lg-block
                      "
                      style="display: inline-block"
                      v-if="offerList.length"
                    >
                      <img
                        src="static/images/icons/bestoffer_icon.png"
                        alt="img"
                        class="img-fluid"
                      />
                      <small
                        class="
                          et-badge
                          transition
                          pl-2
                          pr-2
                          bg-success
                          text-white text-uppercase
                          position-absolute
                        "
                        >Offers</small
                      >
                    </div> -->

                    <!-- <div class="news-content col-sm-10" v-if="offerList.length">
                      <h5
                        class="position-relative dotted-border"
                        v-for="offer in offerList"
                        v-bind:key="offer.offerId"
                      >
                        <span
                          class="
                            text-reset text-capitalize
                            position-relative
                            d-inline-block
                            text-decoration-none
                            bg-white
                            pr-3
                          "
                          >{{ offer.offerDetail }}</span
                        >
                        <span
                          class="
                            float-right
                            text-yellow
                            bg-white
                            position-relative
                            pl-1
                          "
                          ><span class="strikethrough" style="color: red">
                            $ {{ offer.oldPrice }}
                          </span>
                          $ {{ offer.newPrice }}</span
                        >
                        <br /><span class="text-muted">{{
                          offer.description
                        }}</span>
                      </h5>
                      <h5
                        class="font-weight-bold position-relative dotted-border"
                      >
                        <span
                          class="
                            text-reset text-capitalize
                            position-relative
                            d-inline-block
                            text-decoration-none
                            bg-white
                            pr-3
                          "
                          >SINGLE
                        </span>
                        <span
                          class="
                            float-right
                            text-yellow
                            bg-white
                            position-relative
                            pl-1
                          "
                          ><span class="strikethrough" style="color: red">
                            $ 7.99 </span
                          >$ 8.60
                        </span>
                        <br />
                        <span class="text-muted"
                          >( 1 Bed Sheet , 1 Duvet Cover ,1 Pillow Case )</span
                        >
                      </h5>
                    </div> -->
                  </div>

                  <!-- Categories -->
                  <div
                    class="d-sm-block d-lg-flex item align-items-center mt-4"
                    v-for="category in activePriceList"
                    v-bind:key="category.id"
                  >
                    <!-- Small screen -->
                    <div class="row d-lg-none">
                      <div class="col-5 pr-0">
                        <div class="bg-white p-2 row mr-0 ml-0" align="right">
                          <div
                            class="
                              pl-0
                              pr-0
                              d-flex
                              align-items-center
                              position-relative
                              mr-sm-3
                              bg-white
                              p-2
                            "
                          >
                            <img
                              :src="baseUrl + category.categoryImage"
                              :alt="category.categoryName"
                              class="img-fluid"
                              style="width: 70%"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        class="
                          col-7
                          pl-0
                          d-flex
                          align-items-center
                          productData
                          section-heading
                        "
                      >
                        <h2>{{ category.categoryName }}</h2>
                      </div>
                    </div>

                    <!-- On large screen -->
                    <div
                      class="
                        img-thumbnail
                        position-relative
                        mb-4 mb-sm-0
                        mr-sm-3
                        bg-white
                        p-2
                        d-none d-lg-block
                      "
                    >
                      <img
                        :src="baseUrl + category.categoryImage"
                        :alt="category.categoryName"
                        class="img-fluid"
                      />
                      <small
                        class="
                          et-badge
                          transition
                          pl-2
                          pr-2
                          bg-success
                          text-white text-uppercase
                          position-absolute
                        "
                        >{{ category.categoryName }}</small
                      >
                    </div>
                    <!-- Categories Item-->
                    <div class="news-content col-sm-10 mb-5">
                      <h5
                        class="position-relative dotted-border"
                        v-for="priceItems in category.categoryItemList"
                        v-bind:key="priceItems.id"
                      >
                        <span
                          class="
                            text-reset text-capitalize
                            position-relative
                            d-inline-block
                            text-decoration-none
                            bg-white
                            pr-3
                            small-width
                          "
                          style="font-weight: 500"
                          >{{ priceItems.categoryItemName }}</span
                        >
                        <span
                          class="
                            float-right
                            text-yellow
                            bg-white
                            d-inline-block
                            position-relative
                            pl-3
                          "
                          >$ {{ priceItems.price }}</span
                        >
                        <br /><span
                          class="text-muted"
                          style="font-weight: 500"
                          v-if="priceItems.description"
                        >
                          {{ priceItems.description }}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Sidebar -->
          <div
            class="col-lg-4 col-md-12 mt-5 mt-md-0 mb-3"
            v-if="activeInstructionList.length"
          >
            <div class="et-sidebar shadow-box p-4">
              <!-- Sidebar Widget -->
              <div class="side-widget">
                <h5
                  class="
                    widget-heading
                    position position-relative
                    mt-3
                    mb-4
                    text-capitalize text-center
                    font-weight-bold
                  "
                >
                  Please Note
                </h5>
                <div class="widget-content">
                  <div
                    class="meta mb-3 text-left d-flex pr-1 pl-1"
                    v-for="(item, index) in activeInstructionList"
                    v-bind:key="index"
                  >
                    <i
                      class="rounded-pill d-inline-block mr-2 icon-bg bg-white"
                    >
                      <i class="fas fa-check f-icon"></i>
                    </i>
                    <p class="align-bottom description d-inline-block m-0">
                      {{ item }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Services from "@/services/GetServices";
export default {
  name: "Services",
  data() {
    return {
      activeId: 0,
      data1: [], //For services & sub services
      data2: [], //Category/Price List
      data3: [], //Offers of services
      offerList: [],
      activeServiceName: "",
      activeServiceDescription: "",
      activeServiceIcon: "",
      //activeServiceId:0,
      activeSubServiceList: [],
      activePriceList: [],
      isError: false,
      activeServiceList: [],
      activeInstructionList: [],
      baseUrl: this.$api,

      showloading: false,
      activeStep: 1,
    };
  },
  mounted() {
    this.getServicesData();
  },
  methods: {
    async getServicesData() {
      this.showloading = true;
      const response = await Services.getAllServices();
      if (response != undefined) {
        //Success
        if (response.data.isSuccess) {
          //Get Services/Sub services List
          this.data1 = response.data.data1;

          //Get Category/Price list
          this.data2 = response.data.data2;

          //Get offer list
          this.data3 = response.data.data3;

          //Set initial data
          this.showPriceList(this.data1[0]["serviceId"]);

          this.showloading = false;
        }
        //Error
        else {
          this.isError = true;
          this.showloading = false;
        }
      }
      //Error
      else {
        this.$notify.error({
          title: "Oops",
          message: "Something went wrong",
        });

        this.isError = true;
      }
    },

    showPriceList(id) {
      //change service

      this.activeServiceList = this.data1.filter((x) => x.serviceId === id);
      this.activeId = id;

      this.activeServiceName = this.activeServiceList[0]["serviceName"];

      this.activeServiceDescription =
        this.activeServiceList[0]["serviceDetail"];
      this.activeServiceIcon = this.activeServiceList[0]["serviceIcon"];

      this.activeSubServiceList = this.activeServiceList[0]["subServiceList"];

      this.activeInstructionList = JSON.parse(
        this.activeServiceList[0]["instruction"]
      );

      //Get Category/Price list
      this.activePriceList = this.data2.filter(
        (d) => d.serviceId === id && d.categoryItemList.length > 0
      );

      //Get Offer List
      this.offerList = [];
      for (let x = 0; x < this.data3.length; x++) {
        if (this.data3[x]["services"].indexOf(id) > -1) {
          this.offerList.push(this.data3[x]);
        }
      }
    },
  },
};
</script>

<style>
.border-blue {
  border: 2px solid #11b4f5 !important;
  border-radius: 0.25rem;
}

.border-white {
  border: 2px solid #dee2e6 !important;
  border-radius: 0.25rem;
}

.border-black {
  border: 2px solid #1f1f1f !important;
}

.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;

  -webkit-transform: rotate(-21deg);
  -moz-transform: rotate(-21deg);
  -ms-transform: rotate(-21deg);
  -o-transform: rotate(-21deg);
  transform: rotate(-21deg);
}

@media (max-width: 767px) {
  .small-width {
    width: 60%;
  }
  .margin-left {
    margin-left: 0px !important;
  }

  .padding-top {
    padding-top: 0px !important;
  }

  .margin-top {
    margin-top: 0px !important;
  }
}

.marg {
  cursor: pointer;
}
</style>

<style>
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<style>
.loaderss {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #666;
  background-image: url("/static/images/Spinner-1s-200px.gif");
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
