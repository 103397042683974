<template>
  <div><div class="loaderss"></div></div>
</template>

<script>
import checkout from "@/services/checkout";

export default {
  name: "Checkout2",

  components: {},
  mounted() {
    this.checkout(this.$route.params.sessionId);
  },
  methods: {
    async checkout(sessionId) {
      await checkout.checkout(sessionId);
    },
  },
};
</script>
