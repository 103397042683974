<template>
  <section class="container-fluid et-add-restaurant position-relative">
    <!-- <div class="loaderss" v-if="showloading"></div> -->
    <div class="row justify-content-md-center m-0">
      <!-- <customer-navigation /> -->
      <div class="col-md-8 overflow-hidden" style="margin-top: 2%">
        <div class="row justify-content-center">
          <!-- Account -->
          <div class="col-md-10 section-heading text-center mt-4">
            <h2>Terms <span class="text-yellow">& Conditions</span></h2>
          </div>
        </div>
        <div class="w-100 mt-3">
          <div class="content-group">
            <div class="para">
              <p>
                SmartLaundry.com are committed to the protection of private
                information and ensuring compliance with all relevant privacy
                laws.
              </p>
              <p>
                The terms of this Privacy Policy apply to all users of our
                websites and apps. Please read carefully and if you do not agree
                with the terms of this policy, you should immediately
                discontinue the use of our websites or apps and contact us so we
                can remove your details from our database.
              </p>
              <p>
                In this policy, you will find answers to some questions about
                how and why we collect, use, and share your personal
                information.
              </p>
            </div>
            <div class="list">
              <div class="box">
                <h3>[ 1 ] CONTRACT</h3>
                <p>
                  These Terms will apply to any Order we accept from you via our
                  website, mobile app, sms or any other platform. Please note
                  that unless you accept these Terms, you should not be placing
                  an Order As we can accept your order and make a legally
                  enforceable agreement without further reference to you, you
                  must read these terms and conditions to make sure that they
                  contain all that you want and nothing that you are not happy
                  with. Unless you accept these Terms (by ticking the acceptance
                  box when you set up your account, you will not be able to
                  place an order. Prime Laundry reserve the right to change the
                  Terms from time to time. We will notify you of any changes
                  which may affect you by email.
                </p>
              </div>
              <div class="box">
                <h3>[ 2 ] INTERPRETATION</h3>
                <p>
                  App: Smart Laundry Event Outside Our Control: any occurrence
                  or circumstances over which we have no control including the
                  unavailability of any service, provider or key materials
                  without which we are unable to provide the services. Item: any
                  garment or article collected from you in connection with an
                  Order : your order for the Services as set out in the order
                  form submitted via the Smart Laundry App. Services: personal
                  dry cleaning or laundry services collected from and delivered
                  to your nominated address. Service Providers: we are at our
                  facility (5108 N. 19th Avenue, Phoenix, Arizona) provides the
                  Services. Terms: these terms and conditions as amended from
                  time to time; and We/Our/Us: Smart Laundry Limited, company
                  number: (602) 549-9920. Our registered office is at 5108 N.
                  19th Avenue, Phoenix, Arizona. Website: means our website
                  www.SmartLaundry.com on which the Services are advertised.
                </p>
              </div>
              <div class="box">
                <h3>[ 3 ] PLACING AN ORDER</h3>
                <p>
                  When placing an order with us, please ensure that you have
                  checked the details of your requirement before submitting it.
                  We will not be liable to you for errors or omissions you make.
                  Each item needs to be correctly identified. If you have made a
                  mistake, please contact us immediately so we may rectify this
                  for you. A contract is only formed between us once we have
                  sent you an Order acceptance. This email will include your
                  billing information. Each order will be assigned an order
                  number. This order number needs to be quoted in all
                  correspondence and contact with us. All Services which appear
                  on the Application are subject to availability. If we are
                  unable to fulfil your order, we will notify you in writing as
                  soon as practicable.
                </p>
              </div>
              <div class="box">
                <h3>[ 4 ] CHANGES TO AN ORDER</h3>
                <p>
                  Changes can be made to an order 2 hours prior to collection or
                  delivery time as set out in the order acceptance. Changes can
                  be made by app (my order option), by website (my order option)
                  or by contacting our Customer Care at
                  smartlaundryphoenix@gmail.com We may make changes to your
                  order as an alternative to cancellation at our discretion but
                  with your consent. Any changes to your order will be confirmed
                  in writing by email or text.
                </p>
              </div>
              <div class="box">
                <h3>[ 5 ] CANCELLING OR RESCHEDULING YOUR ORDER</h3>
                <p>
                  You have the following limited rights to cancel an Order. You
                  may cancel or reschedule your Order with no additional charge
                  in the following circumstances: at any time up to two hours
                  before the collection time – set out in our email acceptance
                  of your Order – via the App or website or by contacting
                  smartlaundryphoenix@gmail.com. If, after we have collected
                  your Item(s), we are affected by an Event Outside Our Control
                  by contacting our Customer Care at
                  smartlaundryphoenix@gmail.com. You acknowledge that once an
                  Item has been collected from you, we have begun providing the
                  Services to you and that any rights of cancellation or
                  rescheduling you may have under the Consumer Contracts
                  Regulations or any equivalent law or regulations will be lost.
                  Cancelling or rescheduling your order less than two hours
                  before a collection or failing to be present for collection
                  will incur a $5 additional charge. Rescheduling your delivery
                  less than two hours before a delivery or failing to be present
                  for delivery will also incur a $5 additional charge.
                </p>
              </div>
              <div class="box">
                <h3>[ 6 ] OUR RIGHTS TO CANCEL AN ORDER</h3>
                <p>
                  We may cancel your order and subsequently the contract formed
                  between us under the following circumstances: - As a result of
                  an event outside of our control; - If you fail to make the
                  items available for collection as agreed within the order
                  confirmation - If we consider any item not to correspond with
                  the order placed and accepted, is damaged, contains - No item
                  description or cleaning instructions or does not fall within
                  the specified items which we accept. - If we cancel your
                  order, we will notify you in writing by email as quickly as
                  practicable - You will not incur any charges for cleaning
                  already carried out; - We will ensure items are delivered back
                  to you at the original delivery time or as soon as reasonably
                  practicable.
                </p>
              </div>
              <div class="box">
                <h3>[ 7 ] COLLECTION AND RE-DELIVERY</h3>
                <p>
                  Subject to paragraph 12, We will use reasonable endeavours to
                  collect and re-deliver Items at the times specified in the
                  Order but we cannot guarantee to do so. We will use reasonable
                  endeavours to communicate any delay to you by phone or email.
                  If you are not available to accept re-delivery of Items, we
                  will contact you by phone or email to arrange re-delivery at
                  your convenience. If delivery is not possible due to customer
                  being unavailable at the designated time, a re-delivery charge
                  of $5 will be charged for each consequent attempt of delivery
                  as set out in paragraph 5 Smart Laundry will endeavour to find
                  a suitable re-delivery time. However, if a re-delivery is
                  urgent, it is the customers responsibility to organise a same
                  day courier or collection from the Facility. If you have
                  failed to accept or arrange re-delivery of an Item for more
                  than 90 days after the re-delivery date specified in the Order
                  we may dispose of the Item or donate it to an accredited
                  charity of Smart Laundry’s choice. You may arrange to have
                  Items collected from, or re-delivered to, our facility, on
                  condition that you do so at your own risk and our facility is
                  prepared to sign an acknowledgement on your behalf. You may,
                  by written instructions to us, request us to leave an Item in
                  an agreed location without providing us with a signature of
                  acknowledgement. If you do so, it is at Our discretion and
                  entirely at your own risk and we shall not be liable to you
                  for any damage or loss of Items re-delivered on this basis.
                </p>
              </div>
              <div class="box">
                <h3>[ 8 ] STANDARDS OF SERVICE</h3>
                <p>
                  All our work is conducted in line with good industry
                  standards, reasonable skill and care. We will not be held
                  liable for any delay or non-performance of services where it
                  is shown you have failed to provide us with accurate
                  information at the time of order placement. We cannot accept
                  an order that has an incomplete or inaccurate address or if
                  you fail to accept delivery of items as specified in your
                  order. We will contact you in the event the item sent to us is
                  of a higher risk of damage including but not limited to: -
                  Items requiring special treatments or instructions for
                  cleaning - Items where there are no cleaning guidelines
                  present - Items which are damaged or stained - Items
                  containing extraneous or hazardous materials, including pins,
                  jewellery, coins, pens, etc. We provide the services to you at
                  our discretion and we may, with your consent, agree to provide
                  the services regardless of the risk of damage. Wash and Fold
                  Orders:
                </p>
                <p>
                  Please ensure to thoroughly check all the garments for
                  hazardous items, e.g. coins, pens, keys, etc. as we accept no
                  responsibility for any items lost or damaged as a result of
                  the cleaning process. Washing and Drying Process The load is
                  washed at the temperature as set out in our temperature
                  choosing service within our preferences section, then tumble
                  dried where appropriate at a medium heat. Please note that the
                  items are not ironed.
                </p>
                <h5>Colour Separation:</h5>
                <p>
                  We will separate the clothes into lights and darks for you.
                  Although we will take the utmost care in doing this we will
                  accept no responsibility if there is any bleeding or colour
                  transfer during the cleaning process.
                </p>
                <h5>Bag Size and Weight:</h5>
                <p>
                  We will measure the laundry by weight. The minimum weight is
                  6kg. We will update this after we have weighed it at the
                  facility. Any orders below 6kg will be charged at the Wash,
                  Tumble Dry & Fold price.
                </p>
                <h5>Damaged Items:</h5>
                <p>
                  We will not be liable for damage to items as we wash by the
                  load and do not inspect the care labels of each garment. For
                  garments that need to be specially treated, i.e. leather,
                  silk, cashmere, fur, velvet and other delicate garments,
                  please ensure that these items can be machine washed and
                  tumble dried.
                </p>
                <h5>Excluded Items:</h5>
                <p>
                  The Wash and Fold will not include any Duvets, blankets, bath
                  mat or bedspreads. If these are included, then it will be
                  price per items and your bill will be amended accordingly.
                </p>
                <h5>Tagging:</h5>
                <p>
                  Laundry items are tagged by the load and not individually
                  tagged. Therefore, we cannot accept any liability for missing
                  items.
                </p>
              </div>
              <div class="box">
                <h3>[ 9 ] IF THERE IS A PROBLEM WITH THE SERVICES</h3>
                <p>
                  If there is any problem with the Services: - please contact
                  our Customer Care by email at smartlaundryphoenix@gmail.com as
                  detailed on the App and tell Us as soon as reasonably
                  possible; - You will not have to pay for Us to deal with your
                  complaint. - Complaints should be notified to us within 24
                  hours of re-delivery of any Item if you believe that We are in
                  breach of our obligations under these Terms. As a consumer,
                  you have statutory rights if our Services are not carried out
                  with reasonable skill and care, or if the materials used are
                  faulty or not as described. Advice about your legal rights is
                  available from your local Citizens’ Advice Bureau or Trading
                  Standards office. Nothing in these Terms will affect these
                  legal rights.
                </p>
              </div>
              <div class="box">
                <h3>[ 10 ] PRICE AND PAYMENT</h3>
                <p>
                  The price of the Services will be set out in Our price list as
                  set out in the App or at https://smartlaundry.com/ pricing
                  will be the price in force at the time you place your Order.
                  Our prices may change at any time, but price changes will not
                  affect Orders that We have accepted in accordance with
                  paragraph 3. Our prices include VAT. However, if the rate of
                  VAT changes between the date we accept your Order and the date
                  of payment, We will have to adjust the rate of VAT that you
                  pay. Upon placing an Order we capture the payment but do not
                  directly charge your card although some banks will show it as
                  a recent transaction. We process payments when we have
                  received your Order at the facility and after cleaning. If
                  your final total is greater than your captured total (i.e. we
                  need to update your Order accordingly) it may show on your
                  recent transactions but the captured amount will be released
                  when we have taken the full payment. We will take payment from
                  the debit or credit card, details of which were supplied with
                  your Order. If for any reason we are unable to take payment
                  you will become liable to us for the sum due plus interest at
                  the rate 3% a year above the base lending rate of Monzo bank
                  from the time to time. Interest will accrue on a daily basis
                  from due date until the date of actual payment of the overdue
                  amount, whether before or after judgment. You must pay us
                  interest together with any overdue amount.
                </p>
              </div>
              <div class="box">
                <h3>[ 11 ] OUR LIABILITY TO YOU</h3>
                <p>
                  In the unlikely event of loss or damage to an item,
                  SmartLaundry will pay compensation in line with standard
                  industry guidelines. Our total liability to you in respect of
                  each Item is limited to ten times the price we charge for the
                  Services, but not more than $25 per item, given a proof of
                  purchase is provided, showing the date of purchase and the
                  value of the item. Subject to the following sub paragraphs, we
                  will compensate (in accordance with above paragraph) for loss
                  or damage which is due to Our Negligence. We will not be
                  responsible for any single item valued at more than $40. We
                  will not be responsible to you for any loss or damage that is
                  foreseeable. Loss or damage is foreseeable if it is an obvious
                  consequence of Our breach or if it was contemplated by you and
                  us at the time we entered into a contract for the Services. If
                  you use the Services for any commercial, business or re-sale
                  purpose, We have no liability to you for any loss of profit,
                  loss of business, business interruption, or loss of business
                  opportunity. We will not be responsible for any loss or damage
                  (including, without limitation) any colour loss, shrinkage or
                  other damage, resulting from the following: failure to notify
                  us of any special requirements or instructions for cleaning
                  the Item; the fact that the Item has no label indicating
                  cleaning instructions; any existing damage to the Item at the
                  time of collection; any extraneous objects left in or on the
                  Item, including but not limited to: coins, buttons, jewellery,
                  cufflinks, collar stiffeners, pens or tie clips and extraneous
                  packaging provided with the Item, including but not limited
                  to: clothes hangers, suit bags or personal laundry bags We do
                  not exclude or limit in any way Our liability for: death or
                  personal injury caused by negligence; fraud or fraudulent
                  misrepresentation; breach of the terms implied by the Supply
                  of Goods and Services Act 1982 (title and quiet possession);
                  defective products under the Consumer Protection Act 1987. We
                  will not be liable for any consequential loss. We will not be
                  liable for any damage to buttons, zips, and other similar
                  parts (fastenings/belts/embellishments/etc or lost of the
                  items if not mentioned while placing the order.).
                </p>
              </div>
              <div class="box">
                <h3>[ 12 ] EVENTS OUTSIDE OUR CONTROL</h3>
                <p>
                  We will not be liable or responsible for any failure to
                  perform, or delay in performance of, any of Our obligations
                  under these Terms that is caused by an Event Outside Our
                  Control. An Event Outside Our Control means any act or event
                  beyond Our reasonable control, or the reasonable control of
                  any of our Service Providers including (without limitation)
                  strikes, civil commotion, riot, invasion, terrorist attack or
                  threat of terrorist attack, war (whether declared or not) or
                  threat or preparation for war, fire, explosion, storm, flood,
                  earthquake, subsidence, epidemic or other natural disaster, or
                  closure or failure of public infrastructure or public or
                  private telecommunications networks. If an Event Outside Our
                  Control takes place that affects the performance of Our
                  obligations under these Terms: We will contact you as soon as
                  reasonably possible to notify you; and Our obligations under
                  these Terms will be suspended and the time for performance of
                  Our obligations will be extended for the duration of the Event
                  Outside Our Control; or You may cancel your Order under
                  paragraph 5 or We may cancel it under paragraph 6 If your
                  Order is cancelled We will return your Item(s) to you at no
                  cost to you.
                </p>
              </div>
              <div class="box">
                <h3>[ 13 ] INFORMATION ABOUT US AND HOW TO CONTACT US</h3>
                <p>
                  We are a company registered in Phoenix, Arizona. Our company
                  registration number is 46-2476540 and Our registered office is
                  at 5108 N. 19th Avenue, Phoenix, Arizona, USA. If you have any
                  questions or if you have any complaints, please contact us.
                  You can contact us by emailing Us at
                  smartlaundryphoenix@gmail.com or sending a request via the
                  App. If you wish to contact Us in writing, or if any paragraph
                  in these Terms requires you to give Us notice in writing, you
                  can send this to Us by e-mail at . We will confirm receipt of
                  this by email. If We have to contact you or give you notice in
                  writing, We will do so by notification via the App or by
                  e-mail to the address you provide to Us in the Order.
                </p>
              </div>
              <div class="box">
                <h3>[ 14 ] HOW WE MAY USE YOUR PERSONAL INFORMATION</h3>
                <p>
                  We will only use the personal information you provide to us as
                  set out in our Privacy Policy at
                  https://smartlaundry.com/privacy-policy
                </p>
              </div>
              <div class="box">
                <h3>[ 15 ] SMARTLAUNDRY QUALITY GUARANTEE</h3>
                <p>
                  We check each and every garment before it is returned.
                  However, if you are not completely satisfied then simply
                  contact us within 24 hours of delivery and we guarantee to
                  re-clean your items free of charge. Any re-clean requests
                  submitted after 24 hours will be considered on a discretionary
                  basis. To request re-cleaning for your items please email our
                  Customer Care team, explain the problem and attach any
                  relevant photos. A Customer Service agent will contact you by
                  email to arrange a suitable time for recollection.
                </p>
              </div>
              <div class="box">
                <h3>[ 16 ] RE-CLEANING POLICY</h3>
                <p>
                  The re-cleaning only applies to individual items which have
                  been cleaned by Smart Laundry and the original dry cleaning
                  ticket must be attached. Please note it is not always possible
                  to remove stains. If we cannot remove a stain customers will
                  be informed according to the ticket which is attached to their
                  items. In this instance, we are not able to offer a
                  complimentary re-clean.
                </p>
              </div>
              <div class="box">
                <h3>[ 17 ] VOUCHERS & PROMOTIONS</h3>
                <p>
                  Vouchers and promotions are subject to expiry dates and may
                  vary from time to time and may be withdrawn without warning.
                  Prime Laundry vouchers and are subject to our full-service
                  terms and conditions and website terms and conditions.
                  Vouchers cannot be used in conjunction with any other Prime
                  Laundry offer or promotion. Only one voucher can be used in
                  one transaction. Vouchers are strictly non-transferable and
                  have no cash value. Selling of vouchers will render it void.
                  We reserve the right to reject a voucher with reasonable
                  cause. Referral voucher cannot be given to be given / used
                  into the same property. Vouchers can be redeemed at checkout
                  by entering the code found on the voucher. When using a
                  voucher, the minimum order value is $30 or otherwise
                  specified, including the voucher. Vouchers must be used within
                  24 hours of activation. Vouchers are limited to one per
                  household Vouchers cannot be applied to shop items Vouchers
                  cannot be applied to bundles, as these bundles are already
                  heavily discounted.
                </p>
              </div>
              <div class="box">
                <h3>[ 18 ] OTHER IMPORTANT TERMS</h3>
                <p>
                  We may transfer Our rights and obligations under these Terms
                  to another organisation, and We will always notify you in
                  writing if this happens, but this will not affect your rights
                  or Our obligations under these Terms. This contract is between
                  you and us. No other person shall have any rights to enforce
                  any of its terms. Each of the paragraphs of these Terms
                  operates separately. If any court or relevant authority
                  decides that any of them are unlawful, the remaining
                  paragraphs will remain in full force and effect. These Terms
                  are governed by USA law and will be subject to the exclusive
                  jurisdiction of the American courts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style>
.para p {
  padding-top: 5px;
  padding-bottom: 5px;
}
.box {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
