<template>
  <section class="container-fluid et-add-restaurant position-relative">
    <div class="loaderss" v-if="showloading"></div>
    <div class="row justify-content-md-center">
      <!-- <customer-navigation /> -->
      <div class="col-md-8 overflow-hidden" style="margin-top: 2%">
        <div class="row justify-content-center">
          <!-- Account -->
          <div class="col-md-10 section-heading text-center mt-4">
            <h2>My <span class="text-yellow">Account</span></h2>
          </div>
        </div>
        <form class="w-100 add-form mt-3" v-on:submit.prevent="updateProfile">
          <div class="content-group">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize">First Name *</label>
                  <input
                    type="text"
                    autocomplete="on"
                    disabled="disabled"
                    v-model="firstname"
                    required
                    class="form-control bg-white shadow-box"
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize">Last Name *</label>
                  <input
                    type="text"
                    autocomplete="on"
                    disabled="disabled"
                    v-model="lastname"
                    required
                    class="form-control bg-white shadow-box"
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize">Email Id *</label>
                  <input
                    type="email"
                    autocomplete="on"
                    disabled="disabled"
                    v-model="email"
                    required
                    class="form-control bg-white shadow-box"
                    placeholder="Email"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize">Zip Code *</label>
                  <input
                    type="text"
                    autocomplete="on"
                    v-model="postCode"
                    required
                    class="form-control bg-white shadow-box"
                    placeholder="Zip Code"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize"
                    >Address Line1 *</label
                  >
                  <input
                    type="text"
                    autocomplete="on"
                    v-model="address1"
                    required
                    class="form-control bg-white shadow-box"
                    placeholder="Address"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize">
                    Address Line 2</label
                  >
                  <input
                    type="text"
                    autocomplete="on"
                    v-model="address2"
                    class="form-control bg-white shadow-box"
                    placeholder="Extra details"
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize">Mobile No *</label>
                  <div
                    class="row"
                    style="padding-right: 15px; padding-left: 15px"
                  >
                    <input
                      type="number"
                      required
                      autocomplete="on"
                      v-model="mobileNo"
                      class="form-control bg-white shadow-box"
                      placeholder="Mobile No"
                      v-bind:class="
                        this.mobileNo.length == 10 ? 'is-valid' : 'is-invalid'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <input
                :disabled="
                  mobileNo.length == 10 || mobileNo.length == 11 ? false : true
                "
                type="submit"
                class="btn shadow mt-3 bg-yellow text-capitalize text-white"
                value="Update Profile"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import TokenExpireLogin from "@/services/TokenExpireLogin";
// import CustomerNavigation from "@/core/CustomerNavigation.vue";
import UpdateCustomer from "@/services/UpdateCustomer";
import store from "../store/index";
import { decryptAES } from "@/utils/crypto";

export default {
  name: "Account",
  // components: {
  //   CustomerNavigation,
  // },
  data() {
    return {
      showloading: false,
      firstname: "",
      lastname: "",
      address1: "",
      address2: "",
      email: "",
      id: "",
      mobileNo: "",
      postCode: "",
      referFriend: "",
      totalOrder: 0,
    };
  },
  mounted() {
    // Pre fill user data from store
    const userData = this.$store.getters.getUser;

    this.firstname = userData["firstname"];
    this.lastname = userData["lastname"];
    this.address1 = userData["address1"];
    this.address2 = userData["address2"];
    this.email = decryptAES(userData["email"]);
    this.mobileNo = userData["mobileNo"];
    this.postCode = userData["postCode"];
    this.id = Number(decryptAES(userData["id"]));
    this.referFriend = userData["referFriendCode"];
    this.totalOrder = userData["totalOrder"];
  },
  methods: {
    async updateProfile() {
      this.showloading = true;

      const response = await UpdateCustomer.updateCustomer({
        id: this.id,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        mobileNo: this.mobileNo,
        address1: this.address1,
        address2: this.address2,
        postCode: this.postCode,
      });

      if (response) {
        if (response.data.isSuccess) {
          this.$notify({
            title: "Updated",
            message: "Profile Updated Succesfully",
            type: "success",
          });
          //Set data in store
          const user = {
            firstname: this.firstname,
            lastname: this.lastname,
            mobileNo: this.mobileNo,
            address1: this.address1,
            address2: this.address2,
            email: this.$CryptoJS.AES.encrypt(
              this.email,
              Vue.prototype.$secretKey
            ).toString(),
            id: this.$CryptoJS.AES.encrypt(
              this.id.toString(),
              Vue.prototype.$secretKey
            ).toString(),
            postCode: this.postCode,
            totalOrder: this.totalOrder,
            referFriendCode: this.referFriend,
          };

          // commit data in store
          store.commit("setUser", user);
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      } else {
        if (localStorage.getItem("TokenExpire")) {
          const response = await TokenExpireLogin.tokenExpireLogin();
          if (response.data.isSuccess) {
            this.updateProfile();
          }
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      }
      this.showloading = false;
    },
  },
};
</script>

<style scoped>
section.container-fluid {
  padding: 0px 0px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 100px;
}
</style>
