<template>
  <section class="container-fluid et-add-restaurant position-relative">
    <div class="loaderss" v-if="showloading"></div>

    <div class="row justify-content-md-center">
      <!-- <customer-navigation></customer-navigation> -->

      <div class="col-md-8 overflow-hidden" style="margin-top: 2%">
        <div class="row justify-content-center">
          <!-- Change Password -->
          <div class="col-md-10 section-heading text-center mt-4">
            <h2>Change <span class="text-yellow">Password</span></h2>
          </div>
        </div>

        <form class="w-100 add-form mt-3" v-on:submit.prevent="changePassword">
          <div class="content-group">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize"
                    >Old Password *</label
                  >

                  <div class="input-group mb-3">
                    <input
                      :type="passwordFieldType"
                      autocomplete="new-password"
                      class="form-control bg-white shadow-box"
                      placeholder="Old Password"
                      required
                      v-model="oldPassword"
                    />
                    <div class="input-group-prepend">
                      <span
                        @click="switchVisibility()"
                        class="input-group-text"
                        id="basic-addon1"
                        ><i
                          :class="
                            passwordFieldType == 'password'
                              ? 'fas fa-eye'
                              : 'fas fa-eye-slash'
                          "
                        ></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize"
                    >New Password *
                  </label>
                  <div class="input-group">
                    <input
                      :type="passwordFieldType1"
                      autocomplete="new-password"
                      class="form-control bg-white shadow-box"
                      placeholder="New Password"
                      v-model="newPassword"
                      v-bind:class="
                        has_minimum_lenth ? 'is-valid' : 'is-invalid'
                      "
                    />
                    <div class="input-group-prepend">
                      <span
                        @click="switchVisibility(1)"
                        class="input-group-text"
                        id="basic-addon1"
                        ><i
                          :class="
                            passwordFieldType1 == 'password'
                              ? 'fas fa-eye'
                              : 'fas fa-eye-slash'
                          "
                        ></i
                      ></span>
                    </div>
                  </div>

                  <small
                    v-if="has_minimum_lenth ? false : true"
                    class="text-muted"
                    >Password must be at least 6 characters</small
                  >
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group mb-4">
                  <label class="text-muted text-capitalize"
                    >Confirm Password *
                  </label>
                  <div class="input-group mb-3">
                    <input
                      :type="passwordFieldType2"
                      autocomplete="new-password"
                      class="form-control bg-white shadow-box"
                      placeholder="Confirm Password"
                      v-model="confirmPassword"
                      v-bind:class="
                        confirmPassword == newPassword && confirmPassword != ''
                          ? 'is-valid'
                          : 'is-invalid'
                      "
                    />
                    <div class="input-group-prepend">
                      <span
                        @click="switchVisibility(2)"
                        class="input-group-text"
                        id="basic-addon1"
                        ><i
                          :class="
                            passwordFieldType2 == 'password'
                              ? 'fas fa-eye'
                              : 'fas fa-eye-slash'
                          "
                        ></i
                      ></span>
                    </div>
                  </div>

                  <span
                    class="text-danger"
                    v-if="
                      newPassword != confirmPassword &&
                      confirmPassword.length > 0 &&
                      confirmPassword != ''
                        ? true
                        : false
                    "
                    >*password does not match
                  </span>
                </div>
              </div>
            </div>

            <div>
              <input
                :disabled="
                  newPassword == confirmPassword && has_minimum_lenth
                    ? false
                    : true
                "
                type="submit"
                class="btn shadow mt-3 bg-yellow text-capitalize text-white"
                value="Change Password"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import TokenExpireLogin from "@/services/TokenExpireLogin";
// import CustomerNavigation from "@/core/CustomerNavigation.vue";
import UpdateCustomer from "@/services/UpdateCustomer";
import Vue from "vue";

export default {
  name: "ChangePassword",
  // components: {
  //   CustomerNavigation,
  // },
  data() {
    return {
      showloading: false,
      passwordFieldType: "password",
      passwordFieldType1: "password",
      passwordFieldType2: "password",

      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      has_minimum_lenth: false,
    };
  },
  methods: {
    switchVisibility(id) {
      if (id == 1) {
        this.passwordFieldType1 =
          this.passwordFieldType1 === "password" ? "text" : "password";
      } else if (id == 2) {
        this.passwordFieldType2 =
          this.passwordFieldType2 === "password" ? "text" : "password";
      } else {
        this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password";
      }
    },
    async changePassword() {
      this.showloading = true;

      const userData = this.$store.getters.getUser;

      const response = await UpdateCustomer.updatePassword({
        id: Number(
          Vue.CryptoJS.AES.decrypt(
            userData["id"],
            Vue.prototype.$secretKey
          ).toString(Vue.CryptoJS.enc.Utf8)
        ),
        username: Vue.CryptoJS.AES.decrypt(
          userData["email"],
          Vue.prototype.$secretKey
        ).toString(Vue.CryptoJS.enc.Utf8),
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      });
      if (response) {
        if (response.data.isSuccess) {
          this.$notify({
            title: "Success",
            message: "Password Changed",
            type: "success",
          });

          this.oldPassword = "";
          this.newPassword = "";
          this.confirmPassword = "";
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Invalid Old Password",
          });

          this.oldPassword = "";
        }
      } else {
        this.oldPassword = "";
        this.newPassword = "";
        this.confirmPassword = "";

        if (localStorage.getItem("TokenExpire")) {
          const response = await TokenExpireLogin.tokenExpireLogin();
          if (response.data.isSuccess) {
            this.changePassword();
          }
        } else {
          this.$notify.error({
            title: "Oops",
            message: "Something went wrong",
          });
        }
      }

      this.showloading = false;
    },

    passwordValid() {
      if (this.has_minimum_lenth) {
        return true;
      } else return false;
    },
  },
  watch: {
    newPassword() {
      this.has_minimum_lenth = this.newPassword.length >= 6;
    },
  },
};
</script>

<style scoped>
section.container-fluid {
  padding: 0px 0px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 100px;
}
</style>
